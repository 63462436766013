import { useSnackbar } from "notistack";
import { axiosRequest } from "../helpers/api-calls";
import { TrashIcon } from "@heroicons/react/24/outline";
import { PencilIcon } from "@heroicons/react/24/solid";

export const ActionButtons = ({
    item,
    index,
    data,
    updateData,
    setAction,
    setActionID,
    setLoading,
    setModal,
    getData,
    url,
}) => {
    const { enqueueSnackbar, closeSnackbar } = useSnackbar();

    return (
        <div className="flex space-x-6">
            <PencilIcon
                className="w-6 hover:scale-[1.15] text-gray-600 transition-transform ease-linear"
                title="edit"
                onClick={(e) => {
                    e.stopPropagation();
                    setAction("edit");
                    setActionID(item.id);
                    setModal(true);
                }}
            />
            <TrashIcon
                className="w-6 text-red-600 hover:scale-[1.15] transition-transform ease-linear"
                title="delete"
                onClick={(e) => {
                    e.stopPropagation();

                    let newArray = [...data];

                    let oldArray = [...data];

                    newArray.splice(index, 1);

                    updateData(newArray);

                    enqueueSnackbar("Item deleted successfully", {
                        anchorOrigin: {
                            vertical: "bottom",
                            horizontal: "left",
                        },
                        autoHideDuration: 10000,
                        action: (
                            <div>
                                <button
                                    className="pt-2 pb-2 text-red-600 rounded px-5 hover:bg-stone-900 transition-all ease-in-out"
                                    onClick={() => {
                                        updateData(oldArray);
                                        closeSnackbar();
                                    }}
                                >
                                    Undo
                                </button>
                                <button
                                    className="pt-2 pb-2 text-sky-600 rounded px-5 hover:bg-stone-900 transition-all ease-in-out"
                                    onClick={() => {
                                        let deleteUrl = `${url.slice(0, -1)}/${
                                            item.id
                                        }`;

                                        deleteUrl =
                                            url === "mortalities"
                                                ? "mortality/" + item.id
                                                : deleteUrl;

                                        axiosRequest(
                                            "delete",
                                            deleteUrl,
                                            data,
                                            setLoading,
                                            setModal,
                                            getData,
                                            updateData,
                                            enqueueSnackbar
                                        );
                                        closeSnackbar();
                                    }}
                                >
                                    Dismiss
                                </button>
                            </div>
                        ),
                        onClose: () => {
                            axiosRequest(
                                "delete",
                                `${url.slice(0, -1)}/${item.id}`,
                                data,
                                setLoading,
                                setModal,
                                getData,
                                updateData,
                                enqueueSnackbar
                            );
                        },
                    });
                }}
            />
        </div>
    );
};
