import { Page } from "../../Container/page";
import { CalendarIcon } from "@heroicons/react/24/outline";
import { simpleDate } from "../../helpers/date";

const parseData = (data) => {
    return data.map((item) => {
        if (item.custodian)
            item.custodian = <p className="capitalize">{item.custodian}</p>;

        if (item.duration)
            item.duration = <p className="capitalize">{item.duration}</p>;

        if (item.type) item.type = <p className="capitalize">{item.type}</p>;

        if (item.location)
            item.location = <p className="capitalize">{item.location}</p>;

        if (item.status)
            item.status = (
                <p className="capitalize">{item.status.replace("_", " ")}</p>
            );

        if (item.start_date)
            item.start_date = (
                <div className="flex flex-row">
                    <CalendarIcon className="w-6 mr-2" />
                    {item.start_date ? simpleDate(item.start_date) : "-"}
                </div>
            );

        return item;
    });
};

const titles = [
    { title: "Case ID", key: "gbv_case_id" },
    { title: "Type", key: "type" },
    { title: "Location", key: "location" },
    { title: "Duration", key: "duration" },
    { title: "Start Date", key: "start_date" },
    { title: "Custodian", key: "custodian" },
    { title: "Created By", key: "created_by" },
];

export const SafetyReportsPage = () => {
    return (
        <Page
            titles={titles}
            breadCrumb={"GBV / SURVIVOR SAFETY"}
            header={"Survivor Safety"}
            url={"safetyreports"}
            parseData={parseData}
        />
    );
};
