import { Page } from "../../Container/page";
import { CalendarIcon } from "@heroicons/react/24/outline";

const parseData = (data) => {
    return data.map((item) => {
        if (item.health_facility)
            item.health_facility = (
                <p className="capitalize">{item.health_facility}</p>
            );

        if (item.treatments)
            item.treatments = (
                <p
                    className="capitalize truncate w-48"
                    title={item.treatments.join(", ")}
                >
                    {item.treatments.join(", ")}
                </p>
            );

        if (item.medicine)
            item.medicine = (
                <p
                    className="capitalize truncate w-48"
                    title={item.medicine.join(", ")}
                >
                    {item.medicine.join(", ")}
                </p>
            );

        if (item.created_at)
            item.created_at = (
                <div className="flex flex-row">
                    <CalendarIcon className="w-6 mx-4" />
                    {item.created_at.split("T")[0]}
                </div>
            );

        return item;
    });
};

const titles = [
    { title: "Case ID", key: "gbv_case_id" },
    { title: "Health Facility", key: "health_facility" },
    { title: "Treatments", key: "treatments" },
    { title: "Medicine", key: "medicine" },
    { title: "Created By", key: "created_by" },
];

export const MedicalReportsPage = () => {
    return (
        <Page
            titles={titles}
            breadCrumb={"GBV / MEDICAL REPORTS"}
            header={"Medical Reports"}
            url={"medicalreports"}
            parseData={parseData}
        />
    );
};
