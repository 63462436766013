import { Page } from "../Container/page";

const parseData = (data) => {
    return data.map((item) => {
        if (item.first_name)
            item.name = (
                <div className="capitalize">
                    {item.first_name +
                        " " +
                        item.last_name +
                        " " +
                        (item.sir_name ? item.sir_name : "")}
                </div>
            );

        if (item.county)
            item.county = <p className="capitalize">{item.county}</p>;

        if (item.position)
            item.position = <p className="capitalize">{item.position}</p>;

        if (item.location)
            item.location = <p className="capitalize">{item.location}</p>;

        return item;
    });
};

const titles = [
    { title: "Name", key: "name" },
    { title: "Phone Number", key: "phone_number" },
    { title: "National ID", key: "national_id" },
    { title: "County", key: "county" },
    { title: "Location", key: "location" },
    { title: "Women Group", key: "women_group_id" },
    { title: "Position", key: "position" },
];

export const UsersPage = () => {
    return (
        <Page
            titles={titles}
            breadCrumb={
                <div className="flex text-gray-400">
                    <a href="/users" className="hover:text-purple-600">
                        USERS
                    </a>
                </div>
            }
            header={"Users"}
            url={"users"}
            parseData={parseData}
        />
    );
};
