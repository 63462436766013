import { Page } from "../../Container/page";
import { CalendarIcon } from "@heroicons/react/24/outline";
import { simpleDate } from "../../helpers/date";

const parseData = (data) => {
    return data.map((item) => {
        if (item.name)
            item.name = (
                <div className="flex flex-row">
                    <CalendarIcon className="w-6 mr-2" />
                    {item.name.charAt(0).toUpperCase() + item.name.slice(1)}
                </div>
            );

        if (item.date)
            item.date = (
                <div className="flex flex-row">
                    <CalendarIcon className="w-6 mr-2" />
                    {simpleDate(item.date)}
                </div>
            );

        if (item.time)
            item.time = (
                <div className="flex flex-row">
                    <CalendarIcon className="w-6 mr-2" />
                    {item.time.slice(0, -3)}
                </div>
            );

        if (item.moderator)
            item.moderator = <p className="capitalize">{item.moderator}</p>;

        if (item.location)
            item.location = <p className="capitalize">{item.location}</p>;

        if (item.venue) item.venue = <p className="capitalize">{item.venue}</p>;
        return item;
    });
};

const titles = [
    { title: "Name", key: "name" },
    { title: "Moderator", key: "moderator" },
    { title: "Location", key: "location" },
    { title: "Venue", key: "venue" },
    { title: "Date", key: "date" },
    { title: "time", key: "time" },
];
export const LegalAidClinicsPage = () => {
    return (
        <Page
            titles={titles}
            breadCrumb={"GBV / LEGAL AID CLINICS"}
            header={"Legal Aid Clinics"}
            url={"legalaidclinics"}
            parseData={parseData}
        />
    );
};
