import { Page } from "../Container/page";
import { CalendarIcon } from "@heroicons/react/24/outline";
import { BsDot } from "react-icons/bs";
import { simpleDate } from "../helpers/date";

const parseData = (data) => {
    return data.map((item) => {
        if (item.start_date)
            item.start_date = (
                <div className="flex flex-row">
                    <CalendarIcon className="w-6 mr-4" />
                    {simpleDate(item.start_date)}
                </div>
            );

        if (item.goal)
            item.goal = (
                <p>{item.goal.charAt(0).toUpperCase() + item.goal.slice(1)}</p>
            );

        if (item.end_date)
            item.end_date = (
                <div className="flex flex-row">
                    <CalendarIcon className="w-6 mr-4" />
                    {simpleDate(item.end_date)}
                </div>
            );

        if (item.person_responsible)
            item.person_responsible = (
                <p className="capitalize">{item.person_responsible}</p>
            );

        if (item.status) {
            let color = "";
            if (item.status === "pending") color = "text-purple-600";
            if (item.status === "in progress") color = "text-yellow-600";
            if (item.status === "completed") color = "text-green-600";

            item.status = (
                <p className={`flex flex-row items-center capitalize ` + color}>
                    <BsDot className="text-2xl" />
                    {item.status}
                </p>
            );
        }
        return item;
    });
};

const titles = [
    { title: "Goal", key: "goal" },
    { title: "Responsible Party", key: "person_responsible" },
    { title: "Start Date", key: "start_date" },
    { title: "End Date", key: "end_date" },
    { title: "Status", key: "status" },
];

export const ActionPlansPage = () => {
    return (
        <Page
            titles={titles}
            breadCrumb={
                <div className="flex text-gray-400">
                    <a href="/action-plans" className="hover:text-purple-600">
                        ACTION PLANS
                    </a>
                </div>
            }
            header={"Action Plans"}
            url={"actionplans"}
            parseData={parseData}
        />
    );
};
