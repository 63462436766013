import { CaseForm } from "../Components/forms/gbv/case-form";
import { PoliceForm } from "../Components/forms/gbv/police-form";
import { CounsellingForm } from "../Components/forms/gbv/counselling-form";
import { SafetyForm } from "../Components/forms/gbv/safety-form";
import { CourtForm } from "../Components/forms/gbv/court-form";
import { LegalAidClinicForm } from "../Components/forms/gbv/legal-aid-clinics";
import { MedicalForm } from "../Components/forms/gbv/medical-form";
import { ActionPlanForm } from "../Components/forms/action-plan-form";
import { UserForm } from "../Components/forms/user-form";
import { FingerlingForm } from "../Components/forms/fish-management/fingerling-form";
import { SupplierForm } from "../Components/forms/fish-management/supplier-form";
import { MortalitiesForm } from "../Components/forms/fish-management/mortality-form";
import { SaleForm } from "../Components/forms/fish-management/sale-form";
import { BuyerForm } from "../Components/forms/fish-management/buyer-form";
import { WomenGroupForm } from "../Components/forms/fish-management/women-group-form";
import { FeedForm } from "../Components/forms/fish-management/feed-form";
import { BoatForm } from "../Components/forms/fish-management/boat-form";
import { FreezerForm } from "../Components/forms/fish-management/freezer-form";
import { LoanForm } from "../Components/forms/fish-management/loan-form";

export const getForm = (url, getData, setModal, action, actionID) => {
    if (url === "actionplans")
        return (
            <ActionPlanForm
                getData={getData}
                setModal={setModal}
                action={action}
                actionID={actionID}
            />
        );
    if (url === "gbvcases")
        return (
            <CaseForm
                getData={getData}
                setModal={setModal}
                action={action}
                actionID={actionID}
            />
        );
    if (url === "policereports")
        return (
            <PoliceForm
                getData={getData}
                setModal={setModal}
                action={action}
                actionID={actionID}
            />
        );
    if (url === "medicalreports")
        return (
            <MedicalForm
                getData={getData}
                setModal={setModal}
                action={action}
                actionID={actionID}
            />
        );
    if (url === "counsellingreports")
        return (
            <CounsellingForm
                getData={getData}
                setModal={setModal}
                action={action}
                actionID={actionID}
            />
        );
    if (url === "safetyreports")
        return (
            <SafetyForm
                getData={getData}
                setModal={setModal}
                action={action}
                actionID={actionID}
            />
        );
    if (url === "courtcases")
        return (
            <CourtForm
                getData={getData}
                setModal={setModal}
                action={action}
                actionID={actionID}
            />
        );
    if (url === "legalaidclinics")
        return (
            <LegalAidClinicForm
                getData={getData}
                setModal={setModal}
                action={action}
                actionID={actionID}
            />
        );
    if (url === "users")
        return (
            <UserForm
                getData={getData}
                setModal={setModal}
                action={action}
                actionID={actionID}
            />
        );
    if (url === "fingerlings")
        return (
            <FingerlingForm
                getData={getData}
                setModal={setModal}
                action={action}
                actionID={actionID}
            />
        );
    if (url === "mortalities")
        return (
            <MortalitiesForm
                getData={getData}
                setModal={setModal}
                action={action}
                actionID={actionID}
            />
        );
    if (url === "sales")
        return (
            <SaleForm
                getData={getData}
                setModal={setModal}
                action={action}
                actionID={actionID}
            />
        );
    if (url === "feeds")
        return (
            <FeedForm
                getData={getData}
                setModal={setModal}
                action={action}
                actionID={actionID}
            />
        );
    if (url === "boats")
        return (
            <BoatForm
                getData={getData}
                setModal={setModal}
                action={action}
                actionID={actionID}
            />
        );
    if (url === "freezers")
        return (
            <FreezerForm
                getData={getData}
                setModal={setModal}
                action={action}
                actionID={actionID}
            />
        );
    if (url === "buyers")
        return (
            <BuyerForm
                getData={getData}
                setModal={setModal}
                action={action}
                actionID={actionID}
            />
        );
    if (url === "suppliers")
        return (
            <SupplierForm
                getData={getData}
                setModal={setModal}
                action={action}
                actionID={actionID}
            />
        );
    if (url === "womengroups")
        return (
            <WomenGroupForm
                getData={getData}
                setModal={setModal}
                action={action}
                actionID={actionID}
            />
        );
    if (url === "loans")
        return (
            <LoanForm
                getData={getData}
                setModal={setModal}
                action={action}
                actionID={actionID}
            />
        );
};
