import { Page } from "../../Container/page";
import { getTitles } from "../../helpers/titles";
import { parseLoansData } from "../../helpers/data-parses";

export const LoansPage = () => {
    return (
        <Page
            titles={getTitles("loans")}
            breadCrumb={
                <div className="flex text-gray-400">
                    <p>FISH MANAGEMENT / </p>&nbsp;
                    <a
                        href="/fish-management/loans"
                        className="hover:text-purple-600"
                    >
                        REVOLVING LOAN FUND
                    </a>
                </div>
            }
            header={"Revolving Loan Fund"}
            url={"loans"}
            parseData={parseLoansData}
        />
    );
};
