import { useEffect, useState } from "react";
import axios from "axios";
import { useSnackbar } from "notistack";
import { ButtonLoader } from "../../buttonLoader";

export const CourtForm = ({ setModal, getData }) => {
    const [cases, setCases] = useState([]);
    const [error, setError] = useState(false);
    const [loading, setLoading] = useState(false);
    const [data, updateData] = useState({
        case_no: "",
        name: "",
        court: "",
        paralegal: "",
        next_hearing: null,
        action: "",
        gbv_case_id: null,
        created_by: 1,
    });

    const { enqueueSnackbar } = useSnackbar();

    const createGbvData = () => {
        setLoading(true);
        axios({
            method: "post",
            url: process.env.REACT_APP_API_URL + "courtcase",
            data: data,
        })
            .then(({ data }) => {
                if (data?.success) {
                    enqueueSnackbar(data.message, {
                        variant: "success",
                        autoHideDuration: 3000,
                    });
                    setModal(false);
                    getData();
                }

                data?.error &&
                    enqueueSnackbar(data.message, {
                        variant: "error",
                        autoHideDuration: 3000,
                    });
            })
            .catch((error) => {
                enqueueSnackbar(error.message, {
                    variant: "error",
                    autoHideDuration: 3000,
                });
            })
            .finally(() => {
                setLoading(false);
            });
    };

    const getCases = () => {
        axios({
            method: "get",
            url: process.env.REACT_APP_API_URL + "gbvcases",
        })
            .then(({ data }) => {
                data?.success && setCases(data.data);
                data?.error && setError(data);
                setLoading(false);
            })
            .catch((error) => {
                console.log(error.message);
                setError(error.message);
                setLoading(false);
            });
    };

    useEffect(() => {
        getCases();
    }, []);

    if (error) {
        enqueueSnackbar(error, {
            variant: "error",
            autoHideDuration: 3000,
        });
    }

    return (
        <div className="px-6 py-6 lg:px-8">
            <h3 className="mb-4 text-xl font-medium text-gray-900 dark:text-white">
                Create a court case
            </h3>
            <form className="space-y-4" action="#">
                <div>
                    <label
                        htmlFor="case_id"
                        className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                    >
                        Case ID
                    </label>
                    <select
                        id="default"
                        className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-purple-500 focus:border-purple-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-purple-500 dark:focus:border-purple-500"
                        onChange={(event) => {
                            updateData({
                                ...data,
                                gbv_case_id: event.target.value,
                            });
                        }}
                    >
                        <option defaultValue>Choose case</option>
                        {cases.map((item, i) => {
                            return (
                                <option
                                    className="capitalize"
                                    value={item.id}
                                    key={i}
                                >
                                    Case ID: {item.id} - {item.type} -{" "}
                                    {item.name}
                                </option>
                            );
                        })}
                    </select>
                </div>
                <div>
                    <label
                        htmlFor="case_no"
                        className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                    >
                        Case Number
                    </label>
                    <input
                        type="text"
                        name="case_no"
                        id="case_no"
                        className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-purple-500 focus:border-purple-500 block w-full p-2.5 dark:bg-gray-600 dark:border-gray-500 dark:placeholder-gray-400 dark:text-white"
                        placeholder="Enter case number"
                        required
                        value={data.case_no}
                        onChange={(event) => {
                            updateData({
                                ...data,
                                case_no: event.target.value,
                            });
                        }}
                    />
                </div>
                <div>
                    <label
                        htmlFor="name"
                        className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                    >
                        Name
                    </label>
                    <input
                        type="text"
                        name="name"
                        id="name"
                        className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-purple-500 focus:border-purple-500 block w-full p-2.5 dark:bg-gray-600 dark:border-gray-500 dark:placeholder-gray-400 dark:text-white"
                        placeholder="Enter case name"
                        required
                        value={data.name}
                        onChange={(event) => {
                            updateData({
                                ...data,
                                name: event.target.value,
                            });
                        }}
                    />
                </div>
                <div>
                    <label
                        htmlFor="court"
                        className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                    >
                        Court Name
                    </label>
                    <input
                        type="text"
                        name="court"
                        id="court"
                        className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-purple-500 focus:border-purple-500 block w-full p-2.5 dark:bg-gray-600 dark:border-gray-500 dark:placeholder-gray-400 dark:text-white"
                        placeholder="Enter court name"
                        required
                        value={data.court}
                        onChange={(event) => {
                            updateData({
                                ...data,
                                court: event.target.value,
                            });
                        }}
                    />
                </div>
                <div>
                    <label
                        htmlFor="action"
                        className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                    >
                        Action
                    </label>
                    <select
                        id="actio "
                        className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-purple-500 focus:border-purple-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-purple-500 dark:focus:border-purple-500"
                        onChange={(event) => {
                            updateData({
                                ...data,
                                action: event.target.value,
                            });
                        }}
                    >
                        <option defaultValue>Choose action</option>
                        <option value="filed">Filed in court</option>
                        <option value="arbiration">Arbitration</option>
                        <option value="litigation">Litigation</option>
                        <option value="legal counsel">Legal Counsel</option>
                    </select>
                </div>
                <div>
                    <label
                        htmlFor="paralegal"
                        className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                    >
                        Paralegal
                    </label>
                    <input
                        type="text"
                        name="paralegal"
                        id="paralegal"
                        className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-purple-500 focus:border-purple-500 block w-full p-2.5 dark:bg-gray-600 dark:border-gray-500 dark:placeholder-gray-400 dark:text-white"
                        placeholder="Enter paralegal"
                        required
                        value={data.paralegal}
                        onChange={(event) => {
                            updateData({
                                ...data,
                                paralegal: event.target.value,
                            });
                        }}
                    />
                </div>
                <div>
                    <label
                        htmlFor="next_hearing"
                        className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                    >
                        Next Hearing Date
                    </label>
                    <input
                        type="date"
                        name="next_hearing"
                        id="next_hearing"
                        className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-purple-500 focus:border-purple-500 block w-full p-2.5 dark:bg-gray-600 dark:border-gray-500 dark:placeholder-gray-400 dark:text-white"
                        required
                        value={data.next_hearing}
                        onChange={(event) => {
                            updateData({
                                ...data,
                                next_hearing: event.target.value,
                            });
                        }}
                    />
                </div>
                <button
                    type="submit"
                    className="w-full text-white bg-purple-700 hover:bg-purple-800 focus:ring-4 focus:outline-none focus:ring-purple-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-purple-600 dark:hover:bg-purple-700 dark:focus:ring-purple-800"
                    onClick={(e) => {
                        e.preventDefault();
                        createGbvData();
                    }}
                >
                    {loading ? <ButtonLoader /> : "SUBMIT"}
                </button>
            </form>
        </div>
    );
};
