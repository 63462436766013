import { useEffect, useState } from "react";
import { useSnackbar } from "notistack";
import { ButtonLoader } from "../../buttonLoader";
import { Loader } from "../../loader";
import { Input } from "../templates/input";
import { axiosRequest, getWomenGroups } from "../../../helpers/api-calls";
import { SelectGroup } from "../templates/select-group";
import { PlusCircleIcon, TrashIcon } from "@heroicons/react/24/outline";
import { Switch } from "../templates/switch";

export const LoanForm = ({ setModal, getData, action, actionID }) => {
    const [current, setCurrent] = useState("requests");
    const [items, setItems] = useState([
        { item: "", amount: null, description: "" },
    ]);
    const [payments, setPayments] = useState([{ amount: null, date: null }]);
    const [womengroups, setWomenGroups] = useState([]);
    const [disabled, setDisabled] = useState(false);
    const [loading, setLoading] = useState(false);
    const [modalLoading, setModalLoading] = useState(false);
    const [data, updateData] = useState({
        name: "",
        requested_items: [],
        deposit: 20000,
        deposit_paid: false,
        payments: [],
        women_group_id: null,
    });

    const { enqueueSnackbar } = useSnackbar();

    const getAmountRequested = () => {
        let amount_requested = 0;

        items.map((item) => {
            amount_requested +=
                item.amount === null || item.amount === "" ? 0 : item.amount;

            return amount_requested;
        });

        return (
            "KES " +
            parseFloat(amount_requested)
                .toFixed(2)
                .replace(/\B(?=(\d{3})+(?!\d))/g, ",")
        );
    };

    const getAmountPaid = () => {
        let amount_paid = 0;

        if (data.deposit_paid) amount_paid += data.deposit;

        payments.map((payment) => {
            amount_paid +=
                payment.amount === null || payment.amount === ""
                    ? 0
                    : payment.amount;

            return amount_paid;
        });

        return (
            "KES " +
            parseFloat(amount_paid)
                .toFixed(2)
                .replace(/\B(?=(\d{3})+(?!\d))/g, ",")
        );
    };

    const getBalance = () => {
        let balance =
            parseFloat(
                getAmountRequested().replace("KES ", "").replace(/,/g, "")
            ) -
            parseFloat(getAmountPaid().replace("KES ", "").replace(/,/g, ""));

        return (
            "KES " +
            parseFloat(balance)
                .toFixed(2)
                .replace(/\B(?=(\d{3})+(?!\d))/g, ",")
        );
    };

    useEffect(() => {
        getWomenGroups(setWomenGroups);

        if (action === "view") setDisabled(true);

        if (action === "view" || action === "edit")
            axiosRequest(
                "get",
                "loan/" + actionID,
                data,
                setModalLoading,
                setModal,
                getData,
                updateData,
                enqueueSnackbar,
                setItems,
                setPayments
            );
    }, []); // eslint-disable-line

    return (
        <div className="px-6 py-6 lg:px-8">
            {modalLoading ? (
                <div className="h-[calc(100vh-7rem)] flex justify-center items-center">
                    <Loader />
                </div>
            ) : (
                <div>
                    <div className="text-sm font-medium  my-5 text-center text-gray-500 border-b border-gray-200">
                        <ul className="flex justify-between -mb-px ">
                            {["requests", "payments", "balance"].map(
                                (item, index) => {
                                    return (
                                        <li
                                            key={index}
                                            className={`mr-4 cursor-pointer capitalize inline-block p-4 border-b-2 rounded-t-lg ${
                                                current === item
                                                    ? "text-purple-600 border-b-2 border-purple-600 rounded-t-lg active"
                                                    : "border-transparent hover:text-gray-600 hover:border-gray-300"
                                            }`}
                                            onClick={() => {
                                                setCurrent(item);
                                            }}
                                        >
                                            {item}
                                        </li>
                                    );
                                }
                            )}
                        </ul>
                    </div>

                    <form className="space-y-4" action="#">
                        {current === "requests" && (
                            <div className="space-y-4">
                                <SelectGroup
                                    disabled={disabled}
                                    data={data}
                                    updateData={updateData}
                                    womengroups={womengroups}
                                />

                                {items.map((item, index) => {
                                    return (
                                        <div key={index} className="space-y-2">
                                            <div className="flex space-x-2 justify-between">
                                                <div className="w-full">
                                                    <label
                                                        htmlFor="item"
                                                        className="block mb-2 text-sm font-medium text-gray-900 dark:text-white capitalize"
                                                    >
                                                        Item
                                                    </label>
                                                    <input
                                                        disabled={disabled}
                                                        type="text"
                                                        name="item"
                                                        id="item"
                                                        className={`bg-gray-50 ${
                                                            disabled
                                                                ? "border-none"
                                                                : "border"
                                                        } border-gray-300 outline-none text-gray-900 text-sm rounded-lg focus:ring-purple-500 focus:border-purple-500 block w-full p-2.5 dark:bg-gray-600 dark:border-gray-500 dark:placeholder-gray-400 dark:text-white`}
                                                        placeholder="Enter Item"
                                                        value={item["item"]}
                                                        onChange={(event) => {
                                                            let newItems = [
                                                                ...items,
                                                            ];
                                                            newItems[index][
                                                                "item"
                                                            ] =
                                                                event.target.value;
                                                            setItems(newItems);
                                                            updateData({
                                                                ...data,
                                                                requested_items:
                                                                    items,
                                                            });
                                                        }}
                                                    />
                                                </div>
                                                <div className="w-full">
                                                    <label
                                                        htmlFor="amount"
                                                        className="block mb-2 text-sm font-medium text-gray-900 dark:text-white capitalize"
                                                    >
                                                        Amount Needed
                                                    </label>
                                                    <input
                                                        disabled={disabled}
                                                        type="number"
                                                        name="amount"
                                                        id="amount"
                                                        className={`bg-gray-50 ${
                                                            disabled
                                                                ? "border-none"
                                                                : "border"
                                                        } border-gray-300 outline-none text-gray-900 text-sm rounded-lg focus:ring-purple-500 focus:border-purple-500 block w-full p-2.5 dark:bg-gray-600 dark:border-gray-500 dark:placeholder-gray-400 dark:text-white`}
                                                        placeholder="Enter amount needed"
                                                        value={item["amount"]}
                                                        onChange={(event) => {
                                                            let newItems = [
                                                                ...items,
                                                            ];
                                                            newItems[index][
                                                                "amount"
                                                            ] = parseInt(
                                                                event.target
                                                                    .value
                                                            );
                                                            setItems(newItems);
                                                            updateData({
                                                                ...data,
                                                                requested_items:
                                                                    items,
                                                            });
                                                        }}
                                                    />
                                                </div>
                                            </div>
                                            <div>
                                                <label
                                                    htmlFor="description"
                                                    className="capitalize block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                                                >
                                                    Description
                                                </label>
                                                <textarea
                                                    disabled={disabled}
                                                    name="description"
                                                    id="description"
                                                    className={`bg-gray-50 ${
                                                        disabled
                                                            ? "border-none"
                                                            : "border"
                                                    } border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-purple-500 focus:border-purple-500 block w-full p-2.5 dark:bg-gray-600 dark:border-gray-500 dark:placeholder-gray-400 dark:text-white`}
                                                    placeholder="Enter description"
                                                    required
                                                    rows="2"
                                                    value={item["description"]}
                                                    onChange={(event) => {
                                                        let newItems = [
                                                            ...items,
                                                        ];
                                                        newItems[index][
                                                            "description"
                                                        ] = event.target.value;
                                                        setItems(newItems);
                                                        updateData({
                                                            ...data,
                                                            requested_items:
                                                                items,
                                                        });
                                                    }}
                                                />
                                            </div>

                                            {action !== "view" && (
                                                <div className="flex justify-end">
                                                    {index > 0 && (
                                                        <button
                                                            className="flex text-red-700 hover:text-red-800 font-medium rounded-lg text-s text-center"
                                                            onClick={(e) => {
                                                                e.preventDefault();
                                                                let newArr = [
                                                                    ...items,
                                                                ];
                                                                newArr.splice(
                                                                    index,
                                                                    1
                                                                );
                                                                setItems(
                                                                    newArr
                                                                );
                                                            }}
                                                        >
                                                            Remove Item
                                                            <TrashIcon className="w-8 pl-2 -mt-[0.2em]" />
                                                        </button>
                                                    )}
                                                </div>
                                            )}
                                        </div>
                                    );
                                })}

                                {action !== "view" && (
                                    <button
                                        className="flex text-purple-700 hover:text-purple-800 font-medium rounded-lg text-s text-center"
                                        onClick={(e) => {
                                            e.preventDefault();
                                            let currentItems = [...items];
                                            currentItems.push({
                                                item: "",
                                                amount: null,
                                                description: "",
                                            });
                                            setItems(currentItems);
                                        }}
                                    >
                                        <PlusCircleIcon className="w-12 pr-5 -mt-[0.2em]" />{" "}
                                        Add Item
                                    </button>
                                )}
                                <div className="flex justify-between border-t-2 py-2 border-dotted font-semibold mb-5 text-black">
                                    <div className="text-xl">
                                        AMOUNT REQUESTED
                                    </div>
                                    <div className="text-xl">
                                        {getAmountRequested()}
                                    </div>
                                </div>
                            </div>
                        )}

                        {current === "payments" && (
                            <div className="space-y-4">
                                <div className="flex space-x-2 justify-between">
                                    <div className="w-full">
                                        <Input
                                            item={"deposit"}
                                            type={"number"}
                                            disabled={disabled}
                                            data={data}
                                            updateData={updateData}
                                        />
                                    </div>
                                    <div className="w-full">
                                        <Switch
                                            item={"deposit_paid"}
                                            label={"Deposit paid?"}
                                            disabled={disabled}
                                            data={data}
                                            updateData={updateData}
                                        />
                                    </div>
                                </div>

                                {payments.map((payment, index) => {
                                    return (
                                        <div className="space-y-2" key={index}>
                                            <div className="flex space-x-2 justify-between">
                                                <div className="w-full">
                                                    <label
                                                        htmlFor="amount"
                                                        className="block mb-2 text-sm font-medium text-gray-900 dark:text-white capitalize"
                                                    >
                                                        Amount Paid
                                                    </label>
                                                    <input
                                                        disabled={disabled}
                                                        type="number"
                                                        name="amount"
                                                        id="amount"
                                                        className={`bg-gray-50 ${
                                                            disabled
                                                                ? "border-none"
                                                                : "border"
                                                        } border-gray-300 outline-none text-gray-900 text-sm rounded-lg focus:ring-purple-500 focus:border-purple-500 block w-full p-2.5 dark:bg-gray-600 dark:border-gray-500 dark:placeholder-gray-400 dark:text-white`}
                                                        placeholder="Enter amount payment"
                                                        value={
                                                            payment["amount"]
                                                        }
                                                        onChange={(event) => {
                                                            let newPayments = [
                                                                ...payments,
                                                            ];
                                                            newPayments[index][
                                                                "amount"
                                                            ] = parseInt(
                                                                event.target
                                                                    .value
                                                            );
                                                            setPayments(
                                                                newPayments
                                                            );
                                                            updateData({
                                                                ...data,
                                                                payments:
                                                                    payments,
                                                            });
                                                        }}
                                                    />
                                                </div>
                                                <div className="w-full">
                                                    <label
                                                        htmlFor="date"
                                                        className="block mb-2 text-sm font-medium text-gray-900 dark:text-white capitalize"
                                                    >
                                                        Date Paid
                                                    </label>
                                                    <input
                                                        disabled={disabled}
                                                        type="date"
                                                        name="date"
                                                        id="date"
                                                        className={`bg-gray-50 ${
                                                            disabled
                                                                ? "border-none"
                                                                : "border"
                                                        } border-gray-300 outline-none text-gray-900 text-sm rounded-lg focus:ring-purple-500 focus:border-purple-500 block w-full p-2.5 dark:bg-gray-600 dark:border-gray-500 dark:placeholder-gray-400 dark:text-white`}
                                                        value={payment["date"]}
                                                        onChange={(event) => {
                                                            let newPayments = [
                                                                ...payments,
                                                            ];
                                                            newPayments[index][
                                                                "date"
                                                            ] =
                                                                event.target.value;

                                                            setPayments(
                                                                newPayments
                                                            );
                                                            updateData({
                                                                ...data,
                                                                payments:
                                                                    payments,
                                                            });
                                                        }}
                                                    />
                                                </div>
                                            </div>

                                            {action !== "view" && (
                                                <div className="flex justify-end">
                                                    {index > 0 && (
                                                        <button
                                                            className="flex text-red-700 hover:text-red-800 font-medium rounded-lg text-s text-center"
                                                            onClick={(e) => {
                                                                e.preventDefault();
                                                                let newArr = [
                                                                    ...payments,
                                                                ];
                                                                newArr.splice(
                                                                    index,
                                                                    1
                                                                );
                                                                setPayments(
                                                                    newArr
                                                                );
                                                            }}
                                                        >
                                                            Remove Payment
                                                            <TrashIcon className="w-8 pl-2 -mt-[0.2em]" />
                                                        </button>
                                                    )}
                                                </div>
                                            )}
                                        </div>
                                    );
                                })}

                                {action !== "view" && (
                                    <button
                                        className="flex text-purple-700 hover:text-purple-800 font-medium rounded-lg text-s text-center"
                                        onClick={(e) => {
                                            e.preventDefault();
                                            let currentPayments = [...payments];
                                            currentPayments.push({
                                                amount: null,
                                                date: null,
                                            });
                                            setPayments(currentPayments);
                                        }}
                                    >
                                        <PlusCircleIcon className="w-12 pr-5 -mt-[0.2em]" />{" "}
                                        Add Payment
                                    </button>
                                )}

                                <div className="flex justify-between border-t-2 py-2 border-dotted font-semibold mb-5 text-black">
                                    <div className="text-xl">AMOUNT PAID</div>
                                    <div className="text-xl">
                                        {getAmountPaid()}
                                    </div>
                                </div>
                            </div>
                        )}

                        {current === "balance" && (
                            <div className="space-y-4 mt-10">
                                <div className="flex justify-between py-1 font-semibold text-gray-400">
                                    <div className="text-xl">
                                        AMOUNT REQUESTED
                                    </div>
                                    <div className="text-xl">
                                        +{getAmountRequested()}
                                    </div>
                                </div>
                                <div className="flex justify-between py-1 font-semibold text-gray-400">
                                    <div className="text-xl">AMOUNT PAID</div>
                                    <div className="text-xl">
                                        +{getAmountPaid()}
                                    </div>
                                </div>
                                <div className="flex justify-between border-t-2 py-2 border-dotted font-semibold mb-5 text-black">
                                    <div className="text-xl">BALANCE</div>
                                    <div className="text-xl">
                                        {getBalance()}
                                    </div>
                                </div>
                            </div>
                        )}

                        {action !== "view" && (
                            <button
                                type="submit"
                                className="w-full text-white bg-purple-700 hover:bg-purple-800 focus:ring-4 focus:outline-none focus:ring-purple-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-purple-600 dark:hover:bg-purple-700 dark:focus:ring-purple-800"
                                onClick={(e) => {
                                    e.preventDefault();
                                    axiosRequest(
                                        action === "create" ? "post" : "patch",
                                        `loan${
                                            action === "edit"
                                                ? "/" + actionID
                                                : ""
                                        }`,
                                        data,
                                        setLoading,
                                        setModal,
                                        getData,
                                        updateData,
                                        enqueueSnackbar
                                    );
                                }}
                            >
                                {loading ? <ButtonLoader /> : "SUBMIT"}
                            </button>
                        )}
                    </form>
                </div>
            )}
        </div>
    );
};
