import { capitalize } from "../../../helpers/string";

const parseValue = (item, value) => {
    if (item === "role" || item === "position") value = value.toLowerCase();

    return value;
};

export const Select = ({ item, list, disabled, data, updateData }) => {
    return (
        <div>
            <label
                htmlFor={item}
                className="capitalize block mb-2 text-sm font-medium text-gray-900 dark:text-white"
            >
                {item.replace(/_/g, " ")}
            </label>
            <select
                disabled={disabled}
                id={item}
                className={`capitalize bg-gray-50 ${
                    disabled ? "border-none" : "border"
                } border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-purple-500 focus:border-purple-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-purple-500 dark:focus:border-purple-500`}
                onChange={(event) => {
                    updateData({
                        ...data,
                        [item]: event.target.value,
                    });
                }}
            >
                <option value={""}>Choose {item.replace(/_/g, " ")}</option>
                {list.map((value) => {
                    return (
                        <option
                            key={value}
                            value={
                                item === "gbv_case_id"
                                    ? value.split(" ")[2]
                                    : parseValue(item, value)
                            }
                            selected={
                                data[item] ==
                                (item === "gbv_case_id"
                                    ? value.split(" ")[2]
                                    : parseValue(item, value))
                            }
                        >
                            {capitalize(value)}
                        </option>
                    );
                })}
            </select>
        </div>
    );
};
