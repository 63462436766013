import { useEffect, useState } from "react";
import { useSnackbar } from "notistack";
import { ButtonLoader } from "../../buttonLoader";
import { Loader } from "../../loader";
import { Input } from "../templates/input";
import { axiosRequest } from "../../../helpers/api-calls";
import { Select } from "../templates/select";
import { Checkbox } from "../templates/checkbox";

export const SupplierForm = ({ setModal, getData, action, actionID }) => {
    const [disabled, setDisabled] = useState(false);
    const [loading, setLoading] = useState(false);
    const [modalLoading, setModalLoading] = useState(false);
    const [data, updateData] = useState({
        company: "",
        location: "",
        category: "",
        services: [],
        contact_person: "",
        phone_number: "",
        email: "",
    });

    const { enqueueSnackbar } = useSnackbar();

    useEffect(() => {
        if (action === "view") setDisabled(true);

        if (action === "view" || action === "edit")
            axiosRequest(
                "get",
                "supplier/" + actionID,
                data,
                setModalLoading,
                setModal,
                getData,
                updateData,
                enqueueSnackbar
            );
    }, []); // eslint-disable-line

    let services =
        data.category === "technology equipment"
            ? [
                  "engine boats",
                  "solar lights",
                  "solar freezers",
                  "pelleting machine",
              ]
            : [data.category];

    return (
        <div className="px-6 py-6 lg:px-8">
            {modalLoading ? (
                <div className="h-[calc(100vh-7rem)] flex justify-center items-center">
                    <Loader />
                </div>
            ) : (
                <div>
                    <div className="mb-4">
                        <h3 className="text-xl font-medium text-gray-900 dark:text-white">
                            {action.charAt(0).toUpperCase() + action.slice(1)}{" "}
                            supplier
                        </h3>
                    </div>
                    <form className="space-y-4" action="#">
                        <Input
                            item={"company"}
                            type={"text"}
                            disabled={disabled}
                            data={data}
                            updateData={updateData}
                        />
                        <Input
                            item={"location"}
                            type={"text"}
                            disabled={disabled}
                            data={data}
                            updateData={updateData}
                        />
                        <Select
                            item={"category"}
                            list={[
                                "technology equipment",
                                "fish feed raw materials",
                                "fingerlings production",
                            ].map((reason) => {
                                return reason;
                            })}
                            disabled={disabled}
                            data={data}
                            updateData={updateData}
                        />

                        {data.category !== "" && (
                            <Checkbox
                                item={"services"}
                                list={services}
                                disabled={disabled}
                                data={data}
                                updateData={updateData}
                            />
                        )}

                        <Input
                            item={"contact_person"}
                            type={"text"}
                            disabled={disabled}
                            data={data}
                            updateData={updateData}
                        />
                        <Input
                            item={"phone_number"}
                            type={"text"}
                            disabled={disabled}
                            data={data}
                            updateData={updateData}
                        />
                        <Input
                            item={"email"}
                            type={"text"}
                            disabled={disabled}
                            data={data}
                            updateData={updateData}
                        />

                        {action !== "view" && (
                            <button
                                type="submit"
                                className="w-full text-white bg-purple-700 hover:bg-purple-800 focus:ring-4 focus:outline-none focus:ring-purple-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-purple-600 dark:hover:bg-purple-700 dark:focus:ring-purple-800"
                                onClick={(e) => {
                                    e.preventDefault();
                                    axiosRequest(
                                        action === "create" ? "post" : "patch",
                                        `supplier${
                                            action === "edit"
                                                ? "/" + actionID
                                                : ""
                                        }`,
                                        data,
                                        setLoading,
                                        setModal,
                                        getData,
                                        updateData,
                                        enqueueSnackbar
                                    );
                                }}
                            >
                                {loading ? <ButtonLoader /> : "SUBMIT"}
                            </button>
                        )}
                    </form>
                </div>
            )}
        </div>
    );
};
