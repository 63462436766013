import { Page } from "../../Container/page";
import { CalendarIcon } from "@heroicons/react/24/outline";
import { simpleDate } from "../../helpers/date";

const parseData = (data) => {
    return data.map((item) => {
        if (item.name) item.name = <p className="capitalize">{item.name}</p>;

        if (item.gender)
            item.gender = <p className="capitalize">{item.gender}</p>;

        if (item.type) item.type = <p className="capitalize">{item.type}</p>;

        if (item.location)
            item.location = <p className="capitalize">{item.location}</p>;

        if (item.status)
            item.status = (
                <p className="capitalize">{item.status.replace("_", " ")}</p>
            );

        if (item.created_at)
            item.created_at = (
                <div className="flex flex-row">
                    <CalendarIcon className="w-6 mr-2" />
                    {simpleDate(item.created_at.split("T")[0])}
                </div>
            );

        return item;
    });
};

const titles = [
    { title: "Case ID", key: "id" },
    { title: "Name", key: "name" },
    { title: "Phone Number", key: "phone_number" },
    { title: "Age", key: "age" },
    { title: "Gender", key: "gender" },
    { title: "Type", key: "type" },
    { title: "Location", key: "location" },
    { title: "Date Posted", key: "created_at" },
];

export const CasesPage = () => {
    return (
        <Page
            titles={titles}
            breadCrumb={"GBV / CASES"}
            header={"Cases"}
            url={"gbvcases"}
            parseData={parseData}
        />
    );
};
