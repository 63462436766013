import { useParams } from "react-router-dom";
import { Page } from "../../Container/page";
import { getTitles } from "../../helpers/titles";
import {
    parseUserData,
    parseFingerlingData,
    parseMortalitiesData,
    parseSalesData,
    parseLoansData,
    parseFeedsData,
    parseFreezersData,
    parseBoatsData,
} from "../../helpers/data-parses";
import { GroupDashboard } from "./dashboard";

export const WomenGroupPage = () => {
    let { id, name, category } = useParams();

    category = category === "members" ? "users" : category;

    let parseData;

    if (category === "users") parseData = parseUserData;
    if (category === "fingerlings") parseData = parseFingerlingData;
    if (category === "mortalities") parseData = parseMortalitiesData;
    if (category === "feeds") parseData = parseFeedsData;
    if (category === "freezers") parseData = parseFreezersData;
    if (category === "boats") parseData = parseBoatsData;
    if (category === "sales") parseData = parseSalesData;
    if (category === "loans") parseData = parseLoansData;

    return category === "dashboard" ? (
        <GroupDashboard />
    ) : (
        <Page
            titles={getTitles(category)}
            breadCrumb={
                <div className="flex text-gray-400">
                    <p>FISH MANAGEMENT</p>
                    <p>&nbsp; / &nbsp;</p>
                    <a
                        href="/fish-management/womengroups"
                        className="hover:text-purple-600"
                    >
                        WOMEN GROUPS
                    </a>
                    <p>&nbsp; / &nbsp;</p>
                    <a
                        href={`/fish-management/womengroup/${id}/${name}/dashboard`}
                        className="hover:text-purple-600"
                    >
                        {name.toUpperCase()}
                    </a>
                </div>
            }
            header={name + " Women Group"}
            url={`womengroup/${id}/${category}`}
            parseData={parseData}
        />
    );
};
