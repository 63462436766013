export const getTitles = (category) => {
    let titles = [
        {
            name: "users",
            titles: [
                { title: "Name", key: "name" },
                { title: "Phone Number", key: "phone_number" },
                { title: "National ID", key: "national_id" },
                { title: "County", key: "county" },
                { title: "Location", key: "location" },
                { title: "Women Group", key: "women_group_id" },
                { title: "Position", key: "position" },
            ],
        },
        {
            name: "fingerlings",
            titles: [
                { title: "Number", key: "number" },
                { title: "Women Group", key: "women_group" },
                { title: "Created By", key: "created_by" },
                { title: "Date", key: "created_at" },
            ],
        },
        {
            name: "mortalities",
            titles: [
                { title: "Number", key: "number" },
                { title: "Reason", key: "reason" },
                { title: "Women Group", key: "women_group" },
                { title: "Created By", key: "created_by" },
                { title: "Date", key: "created_at" },
            ],
        },
        {
            name: "sales",
            titles: [
                { title: "Size", key: "size" },
                { title: "Rate", key: "rate" },
                { title: "Total Weight", key: "total_weight" },
                { title: "Amount", key: "amount" },
                { title: "Women Group", key: "women_group" },
                { title: "Created By", key: "created_by" },
                { title: "Date", key: "created_at" },
            ],
        },
        {
            name: "loans",
            titles: [
                { title: "Name", key: "name" },
                { title: "Amount", key: "amount" },
                { title: "Deposit", key: "deposit" },
                { title: "Deposit Paid", key: "deposit_paid" },
                { title: "Amount Paid", key: "amount_paid" },
                { title: "Balance", key: "balance" },
                { title: "Women Group", key: "women_group" },
            ],
        },
        {
            name: "feeds",
            titles: [
                { title: "Produced", key: "produced" },
                { title: "Ponds", key: "used_in_pond" },
                { title: "Cages", key: "used_in_cages" },
                { title: "Sold", key: "sold" },
                { title: "Amount Made (ksh)", key: "amount_made" },
                { title: "Women Group", key: "women_group" },
                { title: "Created By", key: "created_by" },
                { title: "Date", key: "created_at" },
            ],
        },
        {
            name: "freezers",
            titles: [
                { title: "Items", key: "items" },
                { title: "Amount", key: "amount" },
                { title: "Women Group", key: "women_group" },
                { title: "Created By", key: "created_by" },
                { title: "Date", key: "created_at" },
            ],
        },
        {
            name: "boats",
            titles: [
                { title: "Item", key: "item" },
                { title: "Amount", key: "amount" },
                { title: "Women Group", key: "women_group" },
                { title: "Created By", key: "created_by" },
                { title: "Date", key: "created_at" },
            ],
        },
        {
            name: "buyers",
            titles: [
                { title: "Company", key: "company" },
                { title: "Location", key: "location" },
                { title: "Contact Person", key: "contact_person" },
                { title: "Phone Number", key: "phone_number" },
                { title: "Created By", key: "created_by" },
            ],
        },
        {
            name: "suppliers",
            titles: [
                { title: "Company", key: "company" },
                { title: "Category", key: "category" },
                { title: "Location", key: "location" },
                { title: "Contact Person", key: "contact_person" },
                { title: "Phone Number", key: "phone_number" },
                { title: "Created By", key: "created_by" },
            ],
        },
        {
            name: "womengroups",
            titles: [
                { title: "Name", key: "name" },
                { title: "Location", key: "location" },
                { title: "County", key: "county" },
            ],
        },
    ];

    titles = titles.filter((title) => {
        return title.name === category;
    });

    return titles[0].titles;
};
