import { useEffect, useState } from "react";
import { useSnackbar } from "notistack";
import { ButtonLoader } from "../../buttonLoader";
import { Loader } from "../../loader";
import { Input } from "../templates/input";
import { TextArea } from "../templates/text-area";
import { Select } from "../templates/select";
import { axiosRequest, getCases } from "../../../helpers/api-calls";

export const CounsellingForm = ({ setModal, getData, action, actionID }) => {
    const [cases, setCases] = useState([]);
    const [disabled, setDisabled] = useState(false);
    const [loading, setLoading] = useState(false);
    const [modalLoading, setModalLoading] = useState(false);
    const [data, updateData] = useState({
        total: 6,
        undergone: 0,
        remaining: 6,
        start_date: "",
        next_session: "",
        counsellor: "",
        status: "pending",
        outcome: "",
        reason_for_outcome: "",
        gbv_case_id: null,
        created_by: 1,
    });

    const { enqueueSnackbar } = useSnackbar();

    useEffect(() => {
        getCases(setCases);

        if (action === "view") setDisabled(true);

        if (action === "view" || action === "edit")
            axiosRequest(
                "get",
                "counsellingreport/" + actionID,
                data,
                setModalLoading,
                setModal,
                getData,
                updateData,
                enqueueSnackbar
            );
    }, []); // eslint-disable-line

    return (
        <div className="px-6 py-6 lg:px-8">
            {modalLoading ? (
                <div className="h-[calc(100vh-7rem)] flex justify-center items-center">
                    <Loader />
                </div>
            ) : (
                <div>
                    <div className="mb-4">
                        <h3 className="text-xl font-medium text-gray-900 dark:text-white">
                            {action.charAt(0).toUpperCase() + action.slice(1)}{" "}
                            counselling report
                        </h3>

                        {action === "view" && (
                            <h4 className="text-lg text-purple-600">
                                Case ID: #{data.gbv_case_id}
                            </h4>
                        )}
                    </div>

                    <form className="space-y-4" action="#">
                        <Select
                            item={"gbv_case_id"}
                            list={cases.map((unit) => {
                                return `Case ID: ${unit.id} - ${unit.type} - ${unit.name}`;
                            })}
                            disabled={disabled || action === "edit"}
                            data={data}
                            updateData={updateData}
                        />
                        <Input
                            item={"total"}
                            type={"number"}
                            disabled={disabled}
                            data={data}
                            updateData={updateData}
                        />
                        {action !== "create" && (
                            <div>
                                <Input
                                    item={"undergone"}
                                    type={"number"}
                                    disabled={disabled}
                                    data={data}
                                    updateData={updateData}
                                />
                                {action === "view" && (
                                    <Input
                                        item={"remaining"}
                                        type={"number"}
                                        disabled={true}
                                        data={data}
                                        updateData={updateData}
                                    />
                                )}
                            </div>
                        )}
                        <Input
                            item={"start_date"}
                            type={"date"}
                            disabled={disabled}
                            data={data}
                            updateData={updateData}
                        />
                        <Input
                            item={"next_session"}
                            type={"date"}
                            disabled={disabled}
                            data={data}
                            updateData={updateData}
                        />
                        <Input
                            item={"counsellor"}
                            type={"text"}
                            disabled={disabled}
                            data={data}
                            updateData={updateData}
                        />
                        {action !== "create" && (
                            <div className="space-y-4">
                                <Select
                                    item={"status"}
                                    list={[
                                        "pending",
                                        "in progress",
                                        "completed",
                                    ]}
                                    disabled={disabled}
                                    data={data}
                                    updateData={updateData}
                                />
                                {data.status === "completed" && (
                                    <div className="space-y-4">
                                        <Select
                                            item={"outcome"}
                                            list={[
                                                "successful",
                                                "stopped",
                                                "failed",
                                            ]}
                                            disabled={disabled}
                                            data={data}
                                            updateData={updateData}
                                        />
                                        {data.outcome !== "" && (
                                            <TextArea
                                                item={"reason_for_outcome"}
                                                placeholder={
                                                    "Enter reason for the above outcome"
                                                }
                                                disabled={disabled}
                                                data={data}
                                                updateData={updateData}
                                            />
                                        )}
                                    </div>
                                )}
                            </div>
                        )}

                        {action !== "view" && (
                            <button
                                type="submit"
                                className="w-full text-white bg-purple-700 hover:bg-purple-800 focus:ring-4 focus:outline-none focus:ring-purple-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-purple-600 dark:hover:bg-purple-700 dark:focus:ring-purple-800"
                                onClick={(e) => {
                                    e.preventDefault();
                                    axiosRequest(
                                        action === "create" ? "post" : "patch",
                                        `counsellingreport${
                                            action === "edit"
                                                ? "/" + actionID
                                                : ""
                                        }`,
                                        {
                                            ...data,
                                            remaining:
                                                data.total - data.undergone,
                                        },
                                        setLoading,
                                        setModal,
                                        getData,
                                        updateData,
                                        enqueueSnackbar
                                    );
                                }}
                            >
                                {loading ? <ButtonLoader /> : "SUBMIT"}
                            </button>
                        )}
                    </form>
                </div>
            )}
        </div>
    );
};
