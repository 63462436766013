import { useState } from "react";
import {
    Squares2X2Icon,
    PresentationChartBarIcon,
    LifebuoyIcon,
    ChevronDownIcon,
    HandRaisedIcon,
    UsersIcon,
} from "@heroicons/react/24/outline";

const links = [
    {
        name: "DASHBOARD",
        link: "/",
        icon: <Squares2X2Icon className="w-6" />,
    },
    {
        name: "ACTION PLANS",
        link: "/action-plans",
        icon: <PresentationChartBarIcon className="w-6" />,
    },
    {
        name: "FISH MANAGEMENT",
        link: "/fish-management",
        icon: <LifebuoyIcon className="w-6" />,
        nestedLinks: [
            {
                name: "FINGERLINGS RESTOCKING",
                link: "/fish-management/fingerlings-restocking",
            },
            {
                name: "MORTALITIES",
                link: "/fish-management/mortalities",
            },
            {
                name: "SALES",
                link: "/fish-management/sales",
            },
            {
                name: "FEEDS",
                link: "/fish-management/feeds",
            },
            {
                name: "FREEZERS",
                link: "/fish-management/freezers",
            },
            {
                name: "BOATS",
                link: "/fish-management/boats",
            },
            {
                name: "REVOLVING LOAN FUND",
                link: "/fish-management/loans",
            },
            {
                name: "BUYERS",
                link: "/fish-management/buyers",
            },
            {
                name: "SUPPLIERS",
                link: "/fish-management/suppliers",
            },
            {
                name: "WOMEN GROUPS",
                link: "/fish-management/womengroups",
            },
        ],
    },
    {
        name: "GBV",
        link: "/gbv",
        icon: <HandRaisedIcon className="w-6" />,
        nestedLinks: [
            {
                name: "CASES",
                link: "/gbv/gbv-cases",
            },
            {
                name: "POLICE REPORTS",
                link: "/gbv/police-reports",
            },
            {
                name: "MEDICAL REPORTS",
                link: "/gbv/medical-reports",
            },
            {
                name: "SURVIVOR SAFETY",
                link: "/gbv/safety-reports",
            },
            {
                name: "COUNSELLING",
                link: "/gbv/counselling-reports",
            },
            {
                name: "COURT CASES",
                link: "/gbv/court-cases",
            },
            {
                name: "LEGAL AID CLINICS",
                link: "/gbv/legal-aid-clinics",
            },
        ],
    },
    {
        name: "USERS",
        link: "/users",
        icon: <UsersIcon className="w-6" />,
    },
];

export const NavBar = () => {
    const [selected, setSelected] = useState("");
    return (
        <div className="bg-[#9B51E0] space-y-8 py-7 px-3 text-white lg:w-3/12 w-fit h-screen overflow-auto">
            <img
                className="w-32"
                src="/logo-white.png"
                alt="Echo Network Africa Logo"
            />
            <ul>
                {links.map((link, i) => {
                    return (
                        <div
                            key={i}
                            onClick={() => {
                                if (!link.nestedLinks) {
                                    document.location.href = link.link;
                                } else {
                                    setSelected(
                                        selected === link.name ? "" : link.name
                                    );
                                }
                            }}
                        >
                            <li className="flex justify-between px-4 py-4 my-2 hover:bg-[#934DD5] cursor-pointer rounded-xl transition duration-300 ease-in-out">
                                <div className="flex flex-row space-x-4">
                                    {link.icon}
                                    <p>{link.name}</p>
                                </div>
                                {link.nestedLinks && (
                                    <ChevronDownIcon className="w-5 float-right" />
                                )}
                            </li>
                            {selected === link.name && link?.nestedLinks && (
                                <ul className="flex flex-col ml-8">
                                    {link.nestedLinks.map((item, index) => {
                                        return (
                                            <li
                                                key={index}
                                                className="px-4 py-4 space-x-4 hover:bg-[#934DD5] cursor-pointer rounded-xl transition duration-300 ease-in-out"
                                                onClick={() => {
                                                    document.location.href =
                                                        item.link;
                                                }}
                                            >
                                                {item.name}
                                            </li>
                                        );
                                    })}
                                </ul>
                            )}
                        </div>
                    );
                })}
            </ul>
        </div>
    );
};
