import { useState, useEffect } from "react";
import axios from "axios";
import { useSnackbar } from "notistack";
import { TopHeader } from "../Components/topHeader";
import { LineGraph } from "../Components/forms/charts/line-graph";
import { BsPeople } from "react-icons/bs";
import { Loader } from "../Components/loader";
import { simpleDate } from "../helpers/date";

export const DashboardPage = () => {
    const [data, updateData] = useState(false);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(false);

    const getData = () => {
        axios({
            method: "get",
            url: process.env.REACT_APP_API_URL + "dashboard/admin",
            headers: {
                "Content-Type": "application/json",
                Authorization: "Bearer " + localStorage.getItem("token"),
            },
        })
            .then(({ data }) => {
                data?.success && updateData(data.data);
                data?.error && setError(data.message);
                setLoading(false);
            })
            .catch((error) => {
                setError(error.message);
                setLoading(false);
            });
    };

    useEffect(() => {
        getData();
    }, []);

    const { enqueueSnackbar } = useSnackbar();

    if (error) {
        enqueueSnackbar(error, {
            variant: "error",
            autoHideDuration: 3000,
        });
    }

    const getStatusColor = (status, outcome) => {
        let color;
        if (status == "pending") color = "bg-purple-600";

        if (status == "in progress") color = "bg-yellow-400";

        if (status == "completed" && outcome == "successful")
            color = "bg-green-600";

        if (status == "completed" && outcome == "failed") color = "bg-red-600";

        if (status == "completed" && outcome == "stopped")
            color = "bg-gray-400";

        return color;
    };

    const getStatusWidth = (status) => {
        let color;

        if (status == "pending") color = "25%";
        if (status == "in progress") color = "50%";
        if (status == "completed") color = "100%";

        return color;
    };

    return (
        <div className="bg-zinc-100 w-full h-screen overflow-y-auto">
            <TopHeader
                breadCrumb={
                    <div className="flex text-gray-400">
                        <a href="/" className="hover:text-purple-600">
                            DASHBOARD
                        </a>
                    </div>
                }
            />
            {!error && (
                <div>
                    {loading ? (
                        <div className="py-60">
                            <Loader />
                        </div>
                    ) : (
                        <div className="mt-[5em] p-5 rounded-lg">
                            <section className="flex space-x-4 py-2 items-stretch ">
                                <div className="shadow-md p-5 rounded-lg bg-white w-full">
                                    <div className="flex justify-between">
                                        <h3 className="text-3xl font-semibold mb-5 text-gray-600">
                                            {data.casesStats.today}
                                        </h3>
                                        <BsPeople className="text-3xl text-sky-600" />
                                    </div>
                                    <p className="text-gray-400">
                                        <h5 className="my-1 font-semibold text-gray-600">
                                            GBV CASES
                                        </h5>
                                        REPORTED TODAY
                                    </p>
                                </div>
                                <div className="shadow-md p-5 rounded-lg bg-white w-full">
                                    <div className="flex justify-between">
                                        <h3 className="text-3xl font-semibold mb-5 text-gray-600">
                                            {data.casesStats.thisMonth}
                                        </h3>
                                        <BsPeople className="text-3xl text-sky-600" />
                                    </div>
                                    <p className="text-gray-400">
                                        <h5 className="my-1 font-semibold text-gray-600">
                                            GBV CASES
                                        </h5>
                                        REPORTED THIS MONTH
                                    </p>
                                </div>
                                <div className="shadow-md  p-5 rounded-lg bg-white w-full">
                                    <div className="flex justify-between">
                                        <h3 className="text-3xl font-semibold mb-5 text-gray-600">
                                            {data.casesStats.thisYear}
                                        </h3>
                                        <BsPeople className="text-3xl text-sky-600" />
                                    </div>
                                    <p className="text-gray-400">
                                        <h5 className="my-1 font-semibold text-gray-600">
                                            GBV CASES
                                        </h5>
                                        REPORTED THIS YEAR
                                    </p>
                                </div>
                                <div className="shadow-md  p-5 rounded-lg bg-white w-full">
                                    <div className="flex justify-between">
                                        <h3 className="text-3xl font-semibold mb-5 text-gray-600">
                                            {data.casesStats.overall}
                                        </h3>
                                        <BsPeople className="text-3xl text-sky-600" />
                                    </div>
                                    <p className="text-gray-400">
                                        <h5 className="my-1 font-semibold text-gray-600">
                                            GBV CASES
                                        </h5>
                                        REPORTED OVERALL
                                    </p>
                                </div>
                            </section>
                            <section className="flex space-x-4 py-2 items-stretch ">
                                <div className="shadow-md p-5 rounded-lg bg-white w-8/12">
                                    <p className="text-gray-400">GBV CASES</p>
                                    <div className="h-96">
                                        <LineGraph data={data.graphStats} />
                                    </div>
                                </div>
                                <div className="shadow-md p-5 rounded-lg bg-white w-4/12">
                                    <p className="text-gray-400">
                                        ACTION PLANS
                                    </p>
                                    <div className="h-96 overflow-scroll">
                                        {data.actionPlans.map((plan) => {
                                            return (
                                                <div
                                                    className="my-5"
                                                    key={plan.id}
                                                >
                                                    <h3 className="capitalize pb-2">
                                                        {plan.goal}
                                                    </h3>
                                                    <div className="flex justify-between">
                                                        <p className="capitalize text-gray-500 text-sm">
                                                            {plan.status}
                                                        </p>
                                                        <p className="capitalize text-gray-500 text-sm">
                                                            Due{" "}
                                                            {simpleDate(
                                                                plan.end_date
                                                            )}
                                                        </p>
                                                    </div>

                                                    <div className="w-full bg-gray-200 rounded-full h-2 dark:bg-gray-700">
                                                        <div
                                                            className={`${getStatusColor(
                                                                plan.status,
                                                                plan.outcome
                                                            )} h-2 my-2 rounded-full`}
                                                            style={{
                                                                width: getStatusWidth(
                                                                    plan.status
                                                                ),
                                                            }}
                                                        ></div>
                                                    </div>
                                                </div>
                                            );
                                        })}
                                    </div>
                                </div>
                            </section>
                        </div>
                    )}
                </div>
            )}
        </div>
    );
};
