import { Page } from "../../Container/page";
import { getTitles } from "../../helpers/titles";
import { parseSuppliersData } from "../../helpers/data-parses";

export const SuppliersPage = () => {
    return (
        <Page
            titles={getTitles("suppliers")}
            breadCrumb={
                <div className="flex text-gray-400">
                    <p>FISH MANAGEMENT / </p>&nbsp;
                    <a
                        href="/fish-management/suppliers"
                        className="hover:text-purple-600"
                    >
                        {" "}
                        SUPPLIERS
                    </a>
                </div>
            }
            header={"Suppliers"}
            url={"suppliers"}
            parseData={parseSuppliersData}
        />
    );
};
