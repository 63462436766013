import { ActionButtons } from "./action-buttons";

export const TableData = ({
    titles,
    data,
    updateData,
    setAction,
    setActionID,
    setLoading,
    setModal,
    getData,
    url,
}) => {
    console.log(url.split("/")[0]);
    return (
        <div>
            <table className="w-full">
                <thead className="text-[#00000066] border-b border-zinc-400">
                    <tr>
                        <th className="border-r py-2">#</th>
                        {titles.map((title, index) => (
                            <th
                                className={`${
                                    index + 1 !== titles.length && "border-r"
                                } font-normal px-4 text-left`}
                                key={index}
                            >
                                {title.title}
                            </th>
                        ))}
                        {url.split("/")[0] !== "womengroup" && (
                            <th className="border-l py-2">Actions</th>
                        )}
                    </tr>
                </thead>
                <tbody>
                    {data.map((item, index) => {
                        return (
                            <tr
                                className="hover:bg-gray-50 cursor-pointer border-b border-zinc-100"
                                key={index}
                                onClick={() => {
                                    if (url === "womengroups") {
                                        window.location.href = `womengroup/${item.id}/${item.name.props.children}/dashboard`;
                                    } else {
                                        if (
                                            url.split("/")[0] !== "womengroup"
                                        ) {
                                            setAction("view");
                                            setActionID(item.id);
                                            setModal(true);
                                        }
                                    }
                                }}
                            >
                                <td className="py-3 pl-4">{index + 1}</td>

                                {titles.map((title) => (
                                    <td className="pl-4" key={title.key}>
                                        <div className="flex items-center space-x-1 text-gray-600">
                                            <div>{item[title.key]}</div>
                                        </div>
                                    </td>
                                ))}
                                {url.split("/")[0] !== "womengroup" && (
                                    <td className="px-4">
                                        <ActionButtons
                                            item={item}
                                            index={index}
                                            data={data}
                                            updateData={updateData}
                                            setAction={setAction}
                                            setActionID={setActionID}
                                            setLoading={setLoading}
                                            setModal={setModal}
                                            getData={getData}
                                            url={url}
                                        />
                                    </td>
                                )}
                            </tr>
                        );
                    })}
                </tbody>
            </table>
        </div>
    );
};
