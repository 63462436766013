import { useParams } from "react-router-dom";
const menuItems = [
    "dashboard",
    "members",
    "fingerlings",
    "mortalities",
    "feeds",
    "freezers",
    "boats",
    "sales",
    "loans",
];

export const GroupNavBar = () => {
    let { id, name, category } = useParams();

    return (
        <div className="text-sm font-medium text-center bg-white text-gray-500 border-b border-purple-200 dark:text-gray-400 dark:border-gray-700">
            <ul className="flex justify-between -mb-px">
                {menuItems.map((item, i) => {
                    return (
                        <a
                            key={i}
                            href={`/fish-management/womengroup/${id}/${name}/${item}`}
                            className={`w-full inline-block capitalize p-4  rounded-t-lg border-b-2 ${
                                category === item
                                    ? "border-purple-600 text-purple-600 "
                                    : "hover:border-purple-600 hover:text-purple-600  dark:hover:text-gray-300"
                            }`}
                        >
                            <li>{item.replace(/-/g, " ")}</li>
                        </a>
                    );
                })}
            </ul>
        </div>
    );
};
