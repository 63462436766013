export const Input = ({ item, type, disabled, data, updateData }) => {
    return (
        <div>
            <label
                htmlFor={item}
                className="block mb-2 text-sm font-medium text-gray-900 dark:text-white capitalize"
            >
                {(item === "total_weight" ||
                    item === "produced" ||
                    item === "used_in_cages" ||
                    item === "used_in_pond" ||
                    item === "sold" ||
                    item === "total_weight") &&
                    "Feeds "}
                {item.replace(/_/g, " ")} {item === "rate" && "in KES per kg"}
                {(item === "total_weight" ||
                    item === "produced" ||
                    item === "used_in_cages" ||
                    item === "used_in_pond" ||
                    item === "sold" ||
                    item === "total_weight") &&
                    "in kg"}
            </label>
            <input
                disabled={disabled}
                type={type}
                name={item}
                id={item}
                className={`bg-gray-50 ${
                    disabled ? "border-none" : "border"
                } border-gray-300 outline-none text-gray-900 text-sm rounded-lg focus:ring-purple-500 focus:border-purple-500 block w-full p-2.5 dark:bg-gray-600 dark:border-gray-500 dark:placeholder-gray-400 dark:text-white`}
                placeholder={`Enter ${item.replace(/_/g, " ")}`}
                value={data[item]}
                onChange={(event) => {
                    updateData({
                        ...data,
                        [item]: event.target.value,
                    });
                }}
            />
        </div>
    );
};
