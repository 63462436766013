import { capitalize } from "../../../helpers/string";

export const SelectGroup = ({ disabled, data, updateData, womengroups }) => {
    return (
        <div>
            <label
                htmlFor="women_group_id"
                className="capitalize block mb-2 text-sm font-medium text-gray-900 dark:text-white"
            >
                Women Group
            </label>
            <select
                disabled={disabled}
                id="women_group_id"
                className={`capitalize bg-gray-50 ${
                    disabled ? "border-none" : "border"
                } border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-purple-500 focus:border-purple-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-purple-500 dark:focus:border-purple-500`}
                onChange={(event) => {
                    updateData({
                        ...data,
                        women_group_id: event.target.value,
                    });
                }}
            >
                <option value={""}>Choose Women Group</option>
                {womengroups.map((group, i) => {
                    return (
                        <option
                            key={i}
                            value={group.id}
                            selected={group.id == data.women_group_id}
                        >
                            {capitalize(group.name)}
                        </option>
                    );
                })}
            </select>
        </div>
    );
};
