import { Page } from "../../Container/page";
import { getTitles } from "../../helpers/titles";
import { parseFeedsData } from "../../helpers/data-parses";

export const FeedsPage = () => {
    return (
        <Page
            titles={getTitles("feeds")}
            breadCrumb={
                <div className="flex text-gray-400">
                    <p>FISH MANAGEMENT / </p>&nbsp;
                    <a
                        href="/fish-management/feeds"
                        className="hover:text-purple-600"
                    >
                        FEEDS
                    </a>
                </div>
            }
            header={"Feeds"}
            url={"feeds"}
            parseData={parseFeedsData}
        />
    );
};
