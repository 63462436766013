export const TextArea = ({ item, disabled, placeholder, data, updateData }) => {
    return (
        <div>
            <label
                htmlFor={item}
                className="capitalize block mb-2 text-sm font-medium text-gray-900 dark:text-white"
            >
                {item.replace(/_/g, " ")}
            </label>
            <textarea
                disabled={disabled}
                name={item}
                id={item}
                className={`bg-gray-50 ${
                    disabled ? "border-none" : "border"
                } border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-purple-500 focus:border-purple-500 block w-full p-2.5 dark:bg-gray-600 dark:border-gray-500 dark:placeholder-gray-400 dark:text-white`}
                placeholder={placeholder}
                required
                rows="4"
                value={data[item]}
                onChange={(event) => {
                    updateData({
                        ...data,
                        [item]: event.target.value,
                    });
                }}
            />
        </div>
    );
};
