import axios from "axios";

export const axiosRequest = (
    method,
    url,
    data,
    setLoading,
    setModal,
    getData,
    updateData,
    enqueueSnackbar,
    setItems = null,
    setPayments = null
) => {
    if (method !== "delete") setLoading(true);
    if (method === "patch") {
        delete data["users"];
        delete data["women_groups"];
    }
    axios({
        method: method,
        url: process.env.REACT_APP_API_URL + url,
        data: data,
        headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + localStorage.getItem("token"),
        },
    })
        .then(({ data }) => {
            if (data?.success) {
                if (method === "post" || method === "patch") {
                    enqueueSnackbar(data.message, {
                        variant: "success",
                        autoHideDuration: 3000,
                    });
                    setModal(false);
                    getData();
                }

                if (method === "get") {
                    updateData(data.data[0]);

                    if (url.split("/")[0] === "loan") {
                        let items = data.data[0].requested_items.map((item) => {
                            return JSON.parse(item);
                        });

                        setItems(items);

                        let payments = data.data[0].payments.map((payment) => {
                            return JSON.parse(payment);
                        });

                        setPayments(payments);
                    }
                }
            }

            data?.error &&
                enqueueSnackbar(data.message, {
                    variant: "error",
                    autoHideDuration: 3000,
                });
        })
        .catch((error) => {
            enqueueSnackbar(error.message, {
                variant: "error",
                autoHideDuration: 3000,
            });
        })
        .finally(() => {
            if (method !== "delete") setLoading(false);
        });
};

export const getCases = (setCases, enqueueSnackbar) => {
    axios({
        method: "get",
        url: process.env.REACT_APP_API_URL + "gbvcases",
        headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + localStorage.getItem("token"),
        },
    })
        .then(({ data }) => {
            data?.success && setCases(data.data);

            data?.error &&
                enqueueSnackbar(data.message, {
                    variant: "error",
                    autoHideDuration: 3000,
                });
        })
        .catch((error) => {
            enqueueSnackbar(error.message, {
                variant: "error",
                autoHideDuration: 3000,
            });
        });
};

export const getWomenGroups = (setWomenGroups, enqueueSnackbar) => {
    axios({
        method: "get",
        url: process.env.REACT_APP_API_URL + "womengroups",
        headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + localStorage.getItem("token"),
        },
    })
        .then(({ data }) => {
            data?.success && setWomenGroups(data.data);

            data?.error &&
                enqueueSnackbar(data.message, {
                    variant: "error",
                    autoHideDuration: 3000,
                });
        })
        .catch((error) => {
            enqueueSnackbar(error.message, {
                variant: "error",
                autoHideDuration: 3000,
            });
        });
};

export const loginUser = (data, setLoading, enqueueSnackbar) => {
    setLoading(true);
    axios({
        method: "post",
        url: process.env.REACT_APP_API_URL + "auth/user/",
        data: data,
    })
        .then(({ data }) => {
            if (data?.success) {
                localStorage.setItem("token", data.data);
                window.location.replace("/");
            }

            data?.error &&
                enqueueSnackbar(data.message, {
                    variant: "error",
                    autoHideDuration: 3000,
                });
        })
        .catch((error) => {
            enqueueSnackbar(error.message, {
                variant: "error",
                autoHideDuration: 3000,
            });
        })
        .then(() => {
            setLoading(false);
        });
};
