import { useState, useEffect } from "react";
import { useSnackbar } from "notistack";
import { ButtonLoader } from "../buttonLoader";
import { Loader } from "../loader";
import { Input } from "./templates/input";
import { TextArea } from "./templates/text-area";
import { Select } from "./templates/select";
import { axiosRequest } from "../../helpers/api-calls";

export const ActionPlanForm = ({ setModal, getData, action, actionID }) => {
    const [disabled, setDisabled] = useState(false);
    const [loading, setLoading] = useState(false);
    const [modalLoading, setModalLoading] = useState(false);
    const [data, updateData] = useState({
        goal: "",
        description: "",
        person_responsible: "",
        start_date: "",
        end_date: "",
        resources_required: "",
        status: "pending",
        outcome: "",
        reason_for_outcome: "",
        platform: "web",
        created_by: 1,
    });

    const { enqueueSnackbar } = useSnackbar();

    useEffect(() => {
        if (action === "view") setDisabled(true);

        if (action === "view" || action === "edit")
            axiosRequest(
                "get",
                "actionplan/" + actionID,
                data,
                setModalLoading,
                setModal,
                getData,
                updateData,
                enqueueSnackbar
            );
    }, []); // eslint-disable-line

    return (
        <div className="px-6 py-6 lg:px-8">
            {modalLoading ? (
                <div className="h-[calc(100vh-7rem)] flex justify-center items-center">
                    <Loader />
                </div>
            ) : (
                <div>
                    <h3 className="mb-4 text-xl font-medium text-gray-900 dark:text-white">
                        {action.charAt(0).toUpperCase() + action.slice(1)}{" "}
                        action plan
                    </h3>
                    <form className="space-y-4" action="#">
                        <Input
                            item={"goal"}
                            type={"text"}
                            disabled={disabled}
                            data={data}
                            updateData={updateData}
                        />
                        <TextArea
                            item={"description"}
                            placeholder={
                                "Write a short description about your action data"
                            }
                            disabled={disabled}
                            data={data}
                            updateData={updateData}
                        />
                        <TextArea
                            item={"resources_required"}
                            placeholder={"What resources do you require"}
                            disabled={disabled}
                            data={data}
                            updateData={updateData}
                        />
                        <Input
                            item={"person_responsible"}
                            type={"text"}
                            disabled={disabled}
                            data={data}
                            updateData={updateData}
                        />
                        <div className="flex space-x-2 justify-between">
                            <div className="w-full">
                                <Input
                                    item={"start_date"}
                                    type={"date"}
                                    disabled={disabled}
                                    data={data}
                                    updateData={updateData}
                                />
                            </div>
                            <div className="w-full">
                                <Input
                                    item={"end_date"}
                                    type={"date"}
                                    disabled={disabled}
                                    data={data}
                                    updateData={updateData}
                                />
                            </div>
                        </div>
                        {action !== "create" && (
                            <div className="space-y-4">
                                <Select
                                    item={"status"}
                                    list={[
                                        "pending",
                                        "in progress",
                                        "completed",
                                    ]}
                                    disabled={disabled}
                                    data={data}
                                    updateData={updateData}
                                />
                                {data.status === "completed" && (
                                    <div className="space-y-4">
                                        <Select
                                            item={"outcome"}
                                            list={[
                                                "successful",
                                                "stopped",
                                                "failed",
                                            ]}
                                            disabled={disabled}
                                            data={data}
                                            updateData={updateData}
                                        />
                                        {data.outcome !== "" && (
                                            <TextArea
                                                item={"reason_for_outcome"}
                                                placeholder={
                                                    "Enter reason for the above outcome"
                                                }
                                                disabled={disabled}
                                                data={data}
                                                updateData={updateData}
                                            />
                                        )}
                                    </div>
                                )}
                            </div>
                        )}

                        {action !== "view" && (
                            <button
                                type="submit"
                                className="w-full text-white bg-purple-700 hover:bg-purple-800 focus:ring-4 focus:outline-none focus:ring-purple-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-purple-600 dark:hover:bg-purple-700 dark:focus:ring-purple-800"
                                onClick={(e) => {
                                    e.preventDefault();
                                    if (action === "create")
                                        axiosRequest(
                                            "post",
                                            "actionplan",
                                            data,
                                            setLoading,
                                            setModal,
                                            getData,
                                            updateData,
                                            enqueueSnackbar
                                        );
                                    if (action === "edit")
                                        axiosRequest(
                                            "patch",
                                            "actionplan/" + actionID,
                                            data,
                                            setLoading,
                                            setModal,
                                            getData,
                                            updateData,
                                            enqueueSnackbar
                                        );
                                }}
                            >
                                {loading ? <ButtonLoader /> : "SUBMIT"}
                            </button>
                        )}
                    </form>
                </div>
            )}
        </div>
    );
};
