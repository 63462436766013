import { Page } from "../../Container/page";
import { getTitles } from "../../helpers/titles";
import { parseBoatsData } from "../../helpers/data-parses";

export const BoatsPage = () => {
    return (
        <Page
            titles={getTitles("boats")}
            breadCrumb={
                <div className="flex text-gray-400">
                    <p>FISH MANAGEMENT / </p>&nbsp;
                    <a
                        href="/fish-management/boats"
                        className="hover:text-purple-600"
                    >
                        BOATS
                    </a>
                </div>
            }
            header={"Boats"}
            url={"boats"}
            parseData={parseBoatsData}
        />
    );
};
