import { Page } from "../../Container/page";
import { CalendarIcon } from "@heroicons/react/24/outline";
import { simpleDate } from "../../helpers/date";
import { BsDot } from "react-icons/bs";

const parseData = (data) => {
    return data.map((item) => {
        if (item.name) item.name = <p className="capitalize">{item.name}</p>;

        if (item.paralegal)
            item.paralegal = <p className="capitalize">{item.paralegal}</p>;

        if (item.court) item.court = <p className="capitalize">{item.court}</p>;

        if (item.status) {
            let color = item.status === "pending" ? "text-purple-600" : "";
            item.status = (
                <p className={`flex flex-row items-center capitalize ` + color}>
                    <BsDot className="text-2xl" />
                    {item.status}
                </p>
            );
        }

        if (item.next_hearing)
            item.next_hearing = (
                <div className="flex flex-row">
                    <CalendarIcon className="w-6 mr-2" />
                    {simpleDate(item.next_hearing)}
                </div>
            );

        return item;
    });
};

const titles = [
    { title: "Case ID", key: "gbv_case_id" },
    { title: "case ID", key: "case_no" },
    { title: "Name", key: "name" },
    { title: "Court", key: "court" },
    { title: "Paralegal", key: "paralegal" },
    { title: "Next Hearing", key: "next_hearing" },
    { title: "Status", key: "status" },
    { title: "Created By", key: "created_by" },
];

export const CourtCasesPage = () => {
    return (
        <Page
            titles={titles}
            breadCrumb={"GBV / COURT CASES"}
            header={"Court Cases"}
            url={"courtcases"}
            parseData={parseData}
        />
    );
};
