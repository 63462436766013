import { Page } from "../../Container/page";
import { CalendarIcon } from "@heroicons/react/24/outline";
import { simpleDate } from "../../helpers/date";

const parseData = (data) => {
    return data.map((item) => {
        if (item.police_station)
            item.police_station = (
                <p className="capitalize">{item.police_station}</p>
            );

        if (item.action_taken)
            item.action_taken = (
                <p className="truncate w-48" title={item.action_taken}>
                    {item.action_taken}
                </p>
            );

        if (item.date_reported)
            item.date_reported = (
                <div className="flex flex-row">
                    <CalendarIcon className="w-6 mr-2" />
                    {simpleDate(item.date_reported)}
                </div>
            );

        return item;
    });
};

const titles = [
    { title: "Case ID", key: "gbv_case_id" },
    { title: "OB Number", key: "ob_number" },
    { title: "Police Station", key: "police_station" },
    { title: "Action Taken", key: "action_taken" },
    { title: "Date Reported", key: "date_reported" },
    { title: "Created By", key: "created_by" },
];

export const PoliceReportsPage = () => {
    return (
        <Page
            titles={titles}
            breadCrumb={"GBV / POLICE REPORTS"}
            header={"Police Reports"}
            url={"policereports"}
            parseData={parseData}
        />
    );
};
