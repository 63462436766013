import { CalendarIcon } from "@heroicons/react/24/outline";
import { simpleDate } from "./date";

/** ----------------- FISH MANAGEMENT ---------------- */
export const parseUserData = (data) => {
    return data.map((item) => {
        if (item.first_name)
            item.name = (
                <div className="capitalize">
                    {item.first_name +
                        " " +
                        item.last_name +
                        " " +
                        (item.sir_name ? item.sir_name : "")}
                </div>
            );

        if (item.county)
            item.county = <p className="capitalize">{item.county}</p>;

        if (item.position)
            item.position = <p className="capitalize">{item.position}</p>;

        if (item.location)
            item.location = <p className="capitalize">{item.location}</p>;

        return item;
    });
};

export const parseFingerlingData = (data) => {
    return data.map((item) => {
        if (item.number)
            item.number =
                item.number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") +
                " Fingerlings";

        if (item.women_groups.name)
            item.women_group = (
                <p className="capitalize">{item.women_groups.name}</p>
            );

        if (item.users.first_name)
            item.created_by = (
                <p className="capitalize">
                    {item.users.first_name} {item.users.last_name}
                </p>
            );

        if (item.created_at)
            item.created_at = (
                <div className="flex flex-row">
                    <CalendarIcon className="w-6 mx-4" />
                    {simpleDate(item.created_at.split("T")[0])}
                </div>
            );

        return item;
    });
};

export const parseMortalitiesData = (data) => {
    return data.map((item) => {
        if (item.number)
            item.number =
                item.number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") +
                " Mortalities";

        if (item.reason)
            item.reason = <p className="capitalize">{item.reason}</p>;

        if (item.women_groups.name)
            item.women_group = (
                <p className="capitalize">{item.women_groups.name}</p>
            );

        if (item.users.first_name)
            item.created_by = (
                <p className="capitalize">
                    {item.users.first_name} {item.users.last_name}
                </p>
            );

        if (item.created_at)
            item.created_at = (
                <div className="flex flex-row">
                    <CalendarIcon className="w-6 mx-4" />
                    {simpleDate(item.created_at.split("T")[0])}
                </div>
            );

        return item;
    });
};

export const parseSalesData = (data) => {
    return data.map((item) => {
        if (item.size) item.size = <p className="capitalize">{item.size}</p>;

        if (item.avg_weight)
            item.avg_weight = <p className="capitalize">{item.avg_weight}</p>;

        if (item.total_weight)
            item.amount =
                "KES " +
                (item.total_weight * item.rate)
                    .toString()
                    .replace(/\B(?=(\d{3})+(?!\d))/g, ",");

        if (item.women_groups.name)
            item.women_group = (
                <p className="capitalize">{item.women_groups.name}</p>
            );

        if (item.rate) item.rate = "KES " + item.rate + " per kg";

        if (item.total_weight) item.total_weight = item.total_weight + "kg";

        if (item.users.first_name)
            item.created_by = (
                <p className="capitalize">
                    {item.users.first_name} {item.users.last_name}
                </p>
            );

        if (item.created_at)
            item.created_at = (
                <div className="flex flex-row">
                    <CalendarIcon className="w-6 mx-4" />
                    {simpleDate(item.created_at.split("T")[0])}
                </div>
            );

        return item;
    });
};

export const parseLoansData = (data) => {
    return data.map((item) => {
        let amount_requested = 0;
        let amount_paid = 0;

        if (item.name) item.name = <p className="capitalize">{item.name}</p>;

        if (item.requested_items.length > 0) {
            item.requested_items.map((request) => {
                amount_requested += JSON.parse(request).amount;
                return amount_requested;
            });
            item.amount = (
                <p>
                    KES{" "}
                    {parseFloat(amount_requested)
                        .toFixed(2)
                        .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                </p>
            );
        }

        if (item.deposit_paid) amount_paid += item.deposit;

        if (item.deposit)
            item.deposit = (
                <p>
                    KES{" "}
                    {parseFloat(item.deposit)
                        .toFixed(2)
                        .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                </p>
            );

        item.deposit_paid = item.deposit_paid ? (
            <p className="text-green-600">PAID</p>
        ) : (
            <p className="text-red-600">UNPAID</p>
        );

        if (item.payments) {
            if (item.payments.length > 0) {
                item.payments.map((request) => {
                    amount_paid += JSON.parse(request).amount;
                    return amount_paid;
                });
            }
        }

        item.amount_paid = (
            <p>
                KES{" "}
                {parseFloat(amount_paid)
                    .toFixed(2)
                    .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
            </p>
        );
        item.balance = (
            <p className="capitalize">
                KES{" "}
                {parseFloat(amount_requested - amount_paid)
                    .toFixed(2)
                    .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
            </p>
        );

        if (item.women_groups.name)
            item.women_group = (
                <p className="capitalize">{item.women_groups.name}</p>
            );

        return item;
    });
};

export const parseFeedsData = (data) => {
    return data.map((item) => {
        if (item.produced) item.produced = item.produced + " kg";
        if (item.used_in_cages) item.used_in_cages = item.used_in_cages + " kg";
        if (item.used_in_pond) item.used_in_pond = item.used_in_pond + " kg";
        if (item.sold) item.sold = item.sold + " kg";

        if (item.amount_made)
            item.amount_made =
                "KES " +
                item.amount_made
                    .toString()
                    .replace(/\B(?=(\d{3})+(?!\d))/g, ",");

        if (item.women_groups.name)
            item.women_group = (
                <p className="capitalize">{item.women_groups.name}</p>
            );

        if (item.users.first_name)
            item.created_by = (
                <p className="capitalize">
                    {item.users.first_name} {item.users.last_name}
                </p>
            );

        if (item.created_at)
            item.created_at = (
                <div className="flex flex-row">
                    <CalendarIcon className="w-6 mx-4" />
                    {simpleDate(item.created_at.split("T")[0])}
                </div>
            );

        return item;
    });
};

export const parseFreezersData = (data) => {
    return data.map((item) => {
        if (item.items)
            item.items = <p className="capitalize">{item.items.join(", ")}</p>;

        if (item.amount)
            item.amount =
                "KES " +
                item.amount.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");

        if (item.women_groups.name)
            item.women_group = (
                <p className="capitalize">{item.women_groups.name}</p>
            );

        if (item.users.first_name)
            item.created_by = (
                <p className="capitalize">
                    {item.users.first_name} {item.users.last_name}
                </p>
            );

        if (item.created_at)
            item.created_at = (
                <div className="flex flex-row">
                    <CalendarIcon className="w-6 mx-4" />
                    {simpleDate(item.created_at.split("T")[0])}
                </div>
            );

        return item;
    });
};

export const parseBoatsData = (data) => {
    return data.map((item) => {
        if (item.item) item.item = <p className="capitalize">{item.item}</p>;

        if (item.amount)
            item.amount =
                "KES " +
                item.amount.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");

        if (item.women_groups.name)
            item.women_group = (
                <p className="capitalize">{item.women_groups.name}</p>
            );

        if (item.users.first_name)
            item.created_by = (
                <p className="capitalize">
                    {item.users.first_name} {item.users.last_name}
                </p>
            );

        if (item.created_at)
            item.created_at = (
                <div className="flex flex-row">
                    <CalendarIcon className="w-6 mx-4" />
                    {simpleDate(item.created_at.split("T")[0])}
                </div>
            );

        return item;
    });
};

export const parseSuppliersData = (data) => {
    return data.map((item) => {
        if (item.contact_person)
            item.contact_person = (
                <p className="capitalize">{item.contact_person}</p>
            );

        if (item.category)
            item.category = <p className="capitalize">{item.category}</p>;

        if (item.company)
            item.company = <p className="capitalize">{item.company}</p>;

        if (item.location)
            item.location = <p className="capitalize">{item.location}</p>;

        if (item.users.first_name)
            item.created_by = (
                <p className="capitalize">
                    {item.users.first_name} {item.users.last_name}
                </p>
            );

        return item;
    });
};

export const parseBuyersData = (data) => {
    return data.map((item) => {
        if (item.contact_person)
            item.contact_person = (
                <p className="capitalize">{item.contact_person}</p>
            );

        if (item.company)
            item.company = <p className="capitalize">{item.company}</p>;

        if (item.location)
            item.location = <p className="capitalize">{item.location}</p>;

        if (item.users.first_name)
            item.created_by = (
                <p className="capitalize">
                    {item.users.first_name} {item.users.last_name}
                </p>
            );

        return item;
    });
};

export const parseWomenGroupData = (data) => {
    return data.map((item) => {
        if (item.name) item.name = <p className="capitalize">{item.name}</p>;

        if (item.location)
            item.location = <p className="capitalize">{item.location}</p>;

        if (item.county)
            item.county = <p className="capitalize">{item.county}</p>;

        return item;
    });
};
