import { useEffect, useState } from "react";
import axios from "axios";
import { useSnackbar } from "notistack";
import { ButtonLoader } from "../../buttonLoader";

const treatments = [
    "emergency contraception",
    "pre exposure prophylaxis",
    "post exposure prophylaxis",
    "STI screening and treatment",
    "forensic evidence collection and filled p3 form",
];

const medicines = ["antibiotics", "painkillers"];

export const MedicalForm = ({ setModal, getData }) => {
    const [cases, setCases] = useState([]);
    const [error, setError] = useState(false);
    const [loading, setLoading] = useState(false);
    const [data, updateData] = useState({
        health_facility: "",
        treatments: [],
        medicine: [],
        gbv_case_id: null,
        created_by: 1,
    });

    const { enqueueSnackbar } = useSnackbar();

    const createMedicalReport = () => {
        setLoading(true);
        axios({
            method: "post",
            url: process.env.REACT_APP_API_URL + "medicalreport",
            data: data,
        })
            .then(({ data }) => {
                if (data?.success) {
                    enqueueSnackbar(data.message, {
                        variant: "success",
                        autoHideDuration: 3000,
                    });
                    setModal(false);
                    getData();
                }

                data?.error &&
                    enqueueSnackbar(data.message, {
                        variant: "error",
                        autoHideDuration: 3000,
                    });
            })
            .catch((error) => {
                enqueueSnackbar(error.message, {
                    variant: "error",
                    autoHideDuration: 3000,
                });
            })
            .finally(() => {
                setLoading(false);
            });
    };

    const getCases = () => {
        axios({
            method: "get",
            url: process.env.REACT_APP_API_URL + "gbvcases",
        })
            .then(({ data }) => {
                data?.success && setCases(data.data);
                data?.error && setError(data);
                setLoading(false);
            })
            .catch((error) => {
                console.log(error.message);
                setError(error.message);
                setLoading(false);
            });
    };

    useEffect(() => {
        getCases();
    }, []);

    if (error) {
        enqueueSnackbar(error, {
            variant: "error",
            autoHideDuration: 3000,
        });
    }

    console.log(data);

    return (
        <div className="px-6 py-6 lg:px-8">
            <h3 className="mb-4 text-xl font-medium text-gray-900 dark:text-white">
                Create medical report
            </h3>
            <form className="space-y-4" action="#">
                <div>
                    <label
                        htmlFor="case_id"
                        className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                    >
                        Case ID
                    </label>
                    <select
                        id="default"
                        className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-purple-500 focus:border-purple-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-purple-500 dark:focus:border-purple-500"
                        onChange={(event) => {
                            updateData({
                                ...data,
                                gbv_case_id: event.target.value,
                            });
                        }}
                    >
                        <option defaultValue>Choose case</option>
                        {cases.map((item, i) => {
                            return (
                                <option
                                    className="capitalize"
                                    value={item.id}
                                    key={i}
                                >
                                    Case ID: {item.id} - {item.type} -{" "}
                                    {item.name}
                                </option>
                            );
                        })}
                    </select>
                </div>
                <div>
                    <label
                        htmlFor="health_facilty"
                        className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                    >
                        Health Facility
                    </label>
                    <input
                        type="text"
                        name="health_facility"
                        id="health_facility"
                        className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-purple-500 focus:border-purple-500 block w-full p-2.5 dark:bg-gray-600 dark:border-gray-500 dark:placeholder-gray-400 dark:text-white"
                        placeholder="Enter health facility"
                        required
                        value={data.health_facility}
                        onChange={(event) => {
                            updateData({
                                ...data,
                                health_facility: event.target.value,
                            });
                        }}
                    />
                </div>
                <div>
                    <label
                        htmlFor="treatments"
                        className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                    >
                        Treatments
                    </label>
                    {treatments.map((treatment, i) => {
                        return (
                            <div className="flex items-center" key={i}>
                                <input
                                    id="checked-checkbox"
                                    type="checkbox"
                                    value=""
                                    className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                                    onChange={(event) => {
                                        let newArray = data.treatments;
                                        event.target.checked &&
                                            newArray.push(treatment) &&
                                            updateData({
                                                ...data,
                                                treatments: newArray,
                                            });
                                    }}
                                />
                                <label
                                    htmlFor="checked-checkbox"
                                    className="ml-2 my-2 text-sm font-medium capitalize text-gray-900"
                                >
                                    {treatment}
                                </label>
                            </div>
                        );
                    })}
                </div>
                <div>
                    <label
                        htmlFor="medicine"
                        className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                    >
                        Medicine
                    </label>
                    {medicines.map((medicine, i) => {
                        return (
                            <div className="flex items-center" key={i}>
                                <input
                                    id="checked-checkbox"
                                    type="checkbox"
                                    value=""
                                    className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                                    onChange={(event) => {
                                        let newArray = data.medicine;
                                        event.target.checked &&
                                            newArray.push(medicine) &&
                                            updateData({
                                                ...data,
                                                medicine: newArray,
                                            });
                                    }}
                                />
                                <label
                                    htmlFor="checked-checkbox"
                                    className="ml-2 my-2 text-sm font-medium capitalize text-gray-900"
                                >
                                    {medicine}
                                </label>
                            </div>
                        );
                    })}
                </div>

                <button
                    type="submit"
                    className="w-full text-white bg-purple-700 hover:bg-purple-800 focus:ring-4 focus:outline-none focus:ring-purple-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-purple-600 dark:hover:bg-purple-700 dark:focus:ring-purple-800"
                    onClick={(e) => {
                        e.preventDefault();
                        createMedicalReport();
                    }}
                >
                    {loading ? <ButtonLoader /> : "SUBMIT"}
                </button>
            </form>
        </div>
    );
};
