import React from "react";
import ReactDOM from "react-dom/client";
import { SnackbarProvider } from "notistack";

import App from "./App";
import "./index.css";

ReactDOM.createRoot(document.getElementById("root")).render(
    <SnackbarProvider
        maxSnack={3}
        anchorOrigin={{
            vertical: "top",
            horizontal: "center",
        }}
    >
        <App />
    </SnackbarProvider>
);
