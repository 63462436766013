import { useState, useEffect } from "react";
import { useSnackbar } from "notistack";
import { ButtonLoader } from "../buttonLoader";
import { Loader } from "../loader";
import { Input } from "./templates/input";
import { Select } from "./templates/select";
import { axiosRequest } from "../../helpers/api-calls";
import { formatPhone } from "../../helpers/string";

export const UserForm = ({ setModal, getData, action, actionID }) => {
    const [disabled, setDisabled] = useState(false);
    const [modalLoading, setModalLoading] = useState(false);
    const [loading, setLoading] = useState(false);
    const [data, updateData] = useState({
        first_name: "",
        last_name: "",
        sir_name: "",
        email: "",
        phone_number: "",
        national_id: "",
        gender: "",
        county: "",
        location: "",
        category: "",
        position: "",
        role: "",
        password: "",
        women_group_id: null,
    });

    const { enqueueSnackbar } = useSnackbar();

    useEffect(() => {
        if (action === "view") setDisabled(true);

        if (action === "view" || action === "edit")
            axiosRequest(
                "get",
                "user/" + actionID,
                data,
                setModalLoading,
                setModal,
                getData,
                updateData,
                enqueueSnackbar
            );
    }, []); // eslint-disable-line

    return (
        <div className="px-6 py-6 lg:px-8">
            {modalLoading ? (
                <div className="h-[calc(100vh-7rem)] flex justify-center items-center">
                    <Loader />
                </div>
            ) : (
                <div>
                    <h3 className="mb-4 text-xl font-medium text-gray-900 dark:text-white">
                        {action.charAt(0).toUpperCase() + action.slice(1)} user
                    </h3>
                    <form className="space-y-4" action="#">
                        <div className="flex space-x-2 justify-between">
                            <div className="w-full">
                                <Input
                                    item={"first_name"}
                                    type={"text"}
                                    disabled={disabled}
                                    data={data}
                                    updateData={updateData}
                                />
                            </div>
                            <div className="w-full">
                                <Input
                                    item={"last_name"}
                                    type={"text"}
                                    disabled={disabled}
                                    data={data}
                                    updateData={updateData}
                                />
                            </div>
                        </div>
                        <div className="flex space-x-2 justify-between">
                            <div className="w-full">
                                <Input
                                    item={"email"}
                                    type={"text"}
                                    disabled={disabled}
                                    data={data}
                                    updateData={updateData}
                                />
                            </div>
                            <div className="w-full">
                                <Input
                                    item={"phone_number"}
                                    type={"text"}
                                    disabled={disabled}
                                    data={data}
                                    updateData={updateData}
                                />
                            </div>
                        </div>
                        <div className="flex space-x-2 justify-between">
                            <div className="w-full">
                                <Input
                                    item={"national_id"}
                                    type={"text"}
                                    disabled={disabled}
                                    data={data}
                                    updateData={updateData}
                                />
                            </div>
                            <div className="w-full">
                                <Select
                                    item={"gender"}
                                    list={["female", "male"]}
                                    disabled={disabled}
                                    data={data}
                                    updateData={updateData}
                                />
                            </div>
                        </div>
                        <div className="flex space-x-2 justify-between">
                            <div className="w-full">
                                <Input
                                    item={"county"}
                                    type={"text"}
                                    disabled={disabled}
                                    data={data}
                                    updateData={updateData}
                                />
                            </div>
                            <div className="w-full">
                                <Input
                                    item={"location"}
                                    type={"text"}
                                    disabled={disabled}
                                    data={data}
                                    updateData={updateData}
                                />
                            </div>
                        </div>
                        <Select
                            item={"category"}
                            list={["Women Group", "LIP Partner", "ENA"]}
                            disabled={disabled}
                            data={data}
                            updateData={updateData}
                        />
                        {data.category !== "LIP Partner" &&
                            data.category !== "" && (
                                <div className="flex space-x-2 justify-between">
                                    <div className="w-full">
                                        <Select
                                            item={"position"}
                                            list={
                                                data.category === "Women Group"
                                                    ? [
                                                          "Member",
                                                          "Chairperson",
                                                          "Secretary",
                                                          "GFP",
                                                          "Treasurer",
                                                      ]
                                                    : ["Staff"]
                                            }
                                            disabled={disabled}
                                            data={data}
                                            updateData={updateData}
                                        />
                                    </div>
                                    <div className="w-full">
                                        <Select
                                            item={"role"}
                                            list={
                                                data.category === "Women Group"
                                                    ? [
                                                          "No Permissions",
                                                          "Women Group Admin",
                                                      ]
                                                    : [
                                                          "Group CEO",
                                                          "Admin",
                                                          "Head of Programmes",
                                                          "Program Manager",
                                                          "Entry Assistance",
                                                      ]
                                            }
                                            disabled={disabled}
                                            data={data}
                                            updateData={updateData}
                                        />
                                    </div>
                                </div>
                            )}

                        <Input
                            item={"password"}
                            type={"password"}
                            disabled={disabled}
                            data={data}
                            updateData={updateData}
                        />
                        {action !== "view" && (
                            <button
                                type="submit"
                                className="w-full text-white bg-purple-700 hover:bg-purple-800 focus:ring-4 focus:outline-none focus:ring-purple-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-purple-600 dark:hover:bg-purple-700 dark:focus:ring-purple-800"
                                onClick={(e) => {
                                    e.preventDefault();
                                    axiosRequest(
                                        action === "create" ? "post" : "patch",
                                        `user${
                                            action === "edit"
                                                ? "/" + actionID
                                                : ""
                                        }`,
                                        {
                                            ...data,
                                            phone_number: formatPhone(
                                                data.phone_number
                                            ),
                                        },
                                        setLoading,
                                        setModal,
                                        getData,
                                        updateData,
                                        enqueueSnackbar
                                    );
                                }}
                            >
                                {loading ? <ButtonLoader /> : "SUBMIT"}
                            </button>
                        )}
                    </form>
                </div>
            )}
        </div>
    );
};
