import { Page } from "../../Container/page";
import { CalendarIcon } from "@heroicons/react/24/outline";
import { simpleDate } from "../../helpers/date";
import { BsDot } from "react-icons/bs";

const parseData = (data) => {
    return data.map((item) => {
        if (item.counsellor)
            item.counsellor = <p className="capitalize">{item.counsellor}</p>;

        if (item.start_date)
            item.start_date = (
                <div className="flex flex-row">
                    <CalendarIcon className="w-6 mr-2" />
                    {simpleDate(item.start_date.split("T")[0])}
                </div>
            );

        if (item.next_session)
            item.next_session = (
                <div className="flex flex-row">
                    <CalendarIcon className="w-6 mr-2" />
                    {simpleDate(item.next_session)}
                </div>
            );

        if (item.status) {
            let color = "";
            if (item.status === "pending") color = "text-purple-600";
            if (item.status === "in progress") color = "text-yellow-600";
            if (item.status === "completed") color = "text-green-600";

            item.status = (
                <p className={`flex flex-row items-center capitalize ` + color}>
                    <BsDot className="text-2xl" />
                    {item.status}
                </p>
            );
        }

        return item;
    });
};

const titles = [
    { title: "Case ID", key: "gbv_case_id" },
    { title: "Total", key: "total" },
    { title: "Undergone", key: "undergone" },
    { title: "Remaining", key: "remaining" },
    { title: "Date Started", key: "start_date" },
    { title: "Next Session", key: "next_session" },
    { title: "Status", key: "status" },
    { title: "Created By", key: "created_by" },
];

export const CounsellingReportsPage = () => {
    return (
        <Page
            titles={titles}
            breadCrumb={"GBV / COUNSELLING REPORTS"}
            header={"Counselling Reports"}
            url={"counsellingreports"}
            parseData={parseData}
        />
    );
};
