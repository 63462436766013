import { useEffect, useState } from "react";
import { useSnackbar } from "notistack";
import { ButtonLoader } from "../../buttonLoader";
import { Loader } from "../../loader";
import { Input } from "../templates/input";
import { axiosRequest, getWomenGroups } from "../../../helpers/api-calls";
import { SelectGroup } from "../templates/select-group";

export const BoatForm = ({ setModal, getData, action, actionID }) => {
    const [womengroups, setWomenGroups] = useState([]);
    const [disabled, setDisabled] = useState(false);
    const [loading, setLoading] = useState(false);
    const [modalLoading, setModalLoading] = useState(false);
    const [data, updateData] = useState({
        item: "",
        amount: null,
        women_group_id: null,
    });

    const { enqueueSnackbar } = useSnackbar();

    useEffect(() => {
        getWomenGroups(setWomenGroups);

        if (action === "view") setDisabled(true);

        if (action === "view" || action === "edit")
            axiosRequest(
                "get",
                "boat/" + actionID,
                data,
                setModalLoading,
                setModal,
                getData,
                updateData,
                enqueueSnackbar
            );
    }, []); // eslint-disable-line

    return (
        <div className="px-6 py-6 lg:px-8">
            {modalLoading ? (
                <div className="h-[calc(100vh-7rem)] flex justify-center items-center">
                    <Loader />
                </div>
            ) : (
                <div>
                    <div className="mb-4">
                        <h3 className="text-xl font-medium text-gray-900 dark:text-white">
                            {action.charAt(0).toUpperCase() + action.slice(1)}{" "}
                            boat income
                        </h3>
                    </div>
                    <form className="space-y-4" action="#">
                        <SelectGroup
                            disabled={disabled}
                            data={data}
                            updateData={updateData}
                            womengroups={womengroups}
                        />
                        <Input
                            item={"item"}
                            type={"text"}
                            disabled={disabled}
                            data={data}
                            updateData={updateData}
                        />
                        <Input
                            item={"amount"}
                            type={"number"}
                            disabled={disabled}
                            data={data}
                            updateData={updateData}
                        />

                        {action !== "view" && (
                            <button
                                type="submit"
                                className="w-full text-white bg-purple-700 hover:bg-purple-800 focus:ring-4 focus:outline-none focus:ring-purple-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-purple-600 dark:hover:bg-purple-700 dark:focus:ring-purple-800"
                                onClick={(e) => {
                                    e.preventDefault();
                                    axiosRequest(
                                        action === "create" ? "post" : "patch",
                                        `boat${
                                            action === "edit"
                                                ? "/" + actionID
                                                : ""
                                        }`,
                                        data,
                                        setLoading,
                                        setModal,
                                        getData,
                                        updateData,
                                        enqueueSnackbar
                                    );
                                }}
                            >
                                {loading ? <ButtonLoader /> : "SUBMIT"}
                            </button>
                        )}
                    </form>
                </div>
            )}
        </div>
    );
};
