import { useState, useEffect } from "react";
import axios from "axios";
import { useSnackbar } from "notistack";
import { TopHeader } from "../Components/topHeader";
import { PlusIcon } from "@heroicons/react/24/outline";
import { Loader } from "../Components/loader";
import { Modal } from "../Components/modal";
import { Empty } from "../Components/empty";
import { TableData } from "../Components/table-data";
import { GroupNavBar } from "../Components/group-nav-bar";
import { getForm } from "../helpers/getForm";

export const Page = ({ titles, breadCrumb, header, url, parseData }) => {
    const [data, updateData] = useState(false);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(false);
    const [modal, setModal] = useState(false);
    const [action, setAction] = useState("create");
    const [actionID, setActionID] = useState(0);

    const getData = () => {
        axios({
            method: "get",
            url: process.env.REACT_APP_API_URL + url,
            headers: {
                "Content-Type": "application/json",
                Authorization: "Bearer " + localStorage.getItem("token"),
            },
        })
            .then(({ data }) => {
                data?.success && updateData(parseData(data.data));
                data?.error && setError(data.message);
                setLoading(false);
            })
            .catch((error) => {
                setError(error.message);
                setLoading(false);
            });
    };

    useEffect(() => {
        getData();
    }, []);

    const { enqueueSnackbar } = useSnackbar();

    if (error) {
        enqueueSnackbar(error, {
            variant: "error",
            autoHideDuration: 3000,
        });
    }

    return (
        <div className="w-full h-screen">
            {modal && (
                <Modal
                    form={getForm(url, getData, setModal, action, actionID)}
                    setModal={setModal}
                />
            )}
            <TopHeader breadCrumb={breadCrumb} />
            <div className="w-full h-screen pt-[7rem] p-5 bg-zinc-100 overflow-y-auto">
                <div className="bg-white min-h-full rounded-lg p-5">
                    <div className="flex justify-between">
                        <h3 className="font-semibold text-xl mb-3 capitalize">
                            {header}
                        </h3>
                        {data && (
                            <p className="text-gray-600 font-regular">
                                {data.length} Result{data.length !== 1 && "s"}
                            </p>
                        )}
                    </div>

                    {url.split("/")[0] === "womengroup" ? (
                        <GroupNavBar />
                    ) : (
                        <div className="flex space-x-2 p-3 mb-3 bg-purple-50 rounded-lg">
                            <PlusIcon
                                className="w-6 cursor-pointer"
                                onClick={() => {
                                    setModal(true);
                                    setAction("create");
                                }}
                            />
                        </div>
                    )}

                    {!error && (
                        <div>
                            {loading ? (
                                <div className="py-60">
                                    <Loader />
                                </div>
                            ) : (
                                <div>
                                    {data.length < 1 ? (
                                        <Empty />
                                    ) : (
                                        <div>
                                            <TableData
                                                titles={titles}
                                                data={data}
                                                updateData={updateData}
                                                setAction={setAction}
                                                setActionID={setActionID}
                                                setLoading={setLoading}
                                                setModal={setModal}
                                                getData={getData}
                                                url={url}
                                            />
                                        </div>
                                    )}
                                </div>
                            )}
                        </div>
                    )}
                </div>
            </div>
        </div>
    );
};
