import { Page } from "../../Container/page";
import { getTitles } from "../../helpers/titles";
import { parseWomenGroupData } from "../../helpers/data-parses";

export const WomenGroupsPage = () => {
    return (
        <Page
            titles={getTitles("womengroups")}
            breadCrumb={
                <div className="flex text-gray-400">
                    <p>FISH MANAGEMENT / </p>&nbsp;
                    <a
                        href="/fish-management/womengroups"
                        className="hover:text-purple-600"
                    >
                        WOMEN GROUPS
                    </a>
                </div>
            }
            header={"Women Groups"}
            url={"womengroups"}
            parseData={parseWomenGroupData}
        />
    );
};
