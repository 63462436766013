export const capitalize = (string) => {
    return string
        .split(" ")
        .map((s) => s.charAt(0).toUpperCase() + s.substring(1))
        .join(" ");
};

export const formatPhone = (phoneNumber) => {
    if (phoneNumber.length < 12) {
        if (phoneNumber.charAt(0) === "0") {
            phoneNumber = phoneNumber.replace("0", "254");
        } else {
            phoneNumber = "254" + phoneNumber;
        }
    }

    return phoneNumber;
};
