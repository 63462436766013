import "./App.css";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import { NavBar } from "./Components/navBar";

import { ActionPlansPage } from "./Pages/action-plans";

import { FingerlingsPage } from "./Pages/fish-management/fingerlings";
import { MortalitiesPage } from "./Pages/fish-management/mortalities";
import { SalesPage } from "./Pages/fish-management/sales";
import { SuppliersPage } from "./Pages/fish-management/suppliers";
import { BuyersPage } from "./Pages/fish-management/buyers";
import { WomenGroupsPage } from "./Pages/fish-management/women-groups";
import { WomenGroupPage } from "./Pages/fish-management/women-group";
import { LoansPage } from "./Pages/fish-management/loans";
import { FeedsPage } from "./Pages/fish-management/feeds";
import { FreezersPage } from "./Pages/fish-management/freezers";
import { BoatsPage } from "./Pages/fish-management/boats";

import { CasesPage } from "./Pages/gbv/gbv-cases";
import { PoliceReportsPage } from "./Pages/gbv/police-reports";
import { SafetyReportsPage } from "./Pages/gbv/safety-reports";
import { MedicalReportsPage } from "./Pages/gbv/medical-reports";
import { CounsellingReportsPage } from "./Pages/gbv/counselling-reports";
import { CourtCasesPage } from "./Pages/gbv/court-cases";
import { LegalAidClinicsPage } from "./Pages/gbv/legal-aid-clinics";

import { UsersPage } from "./Pages/users";
import { DashboardPage } from "./Pages/dashboard";
import { LoginPage } from "./Pages/login";

function App() {
    if (
        localStorage.getItem("token") == null &&
        window.location.pathname !== "/login"
    )
        window.location.replace("/login");

    return (
        <div className="flex overflow-x-hidden">
            <BrowserRouter>
                {window.location.pathname !== "/login" && <NavBar />}
                <Routes>
                    <Route path="/action-plans" element={<ActionPlansPage />} />
                    <Route
                        path="/fish-management/fingerlings-restocking"
                        element={<FingerlingsPage />}
                    />
                    <Route
                        path="/fish-management/mortalities"
                        element={<MortalitiesPage />}
                    />
                    <Route
                        path="/fish-management/sales"
                        element={<SalesPage />}
                    />
                    <Route
                        path="/fish-management/feeds"
                        element={<FeedsPage />}
                    />
                    <Route
                        path="/fish-management/freezers"
                        element={<FreezersPage />}
                    />
                    <Route
                        path="/fish-management/boats"
                        element={<BoatsPage />}
                    />
                    <Route
                        path="/fish-management/suppliers"
                        element={<SuppliersPage />}
                    />
                    <Route
                        path="/fish-management/buyers"
                        element={<BuyersPage />}
                    />
                    <Route
                        path="/fish-management/loans"
                        element={<LoansPage />}
                    />
                    <Route
                        path="/fish-management/womengroups"
                        element={<WomenGroupsPage />}
                    />
                    <Route
                        path="/fish-management/womengroup/:id/:name/:category"
                        element={<WomenGroupPage />}
                    />
                    <Route path="/gbv/gbv-cases" element={<CasesPage />} />
                    <Route
                        path="/gbv/police-reports"
                        element={<PoliceReportsPage />}
                    />
                    <Route
                        path="/gbv/medical-reports"
                        element={<MedicalReportsPage />}
                    />
                    <Route
                        path="/gbv/safety-reports"
                        element={<SafetyReportsPage />}
                    />
                    <Route
                        path="/gbv/counselling-reports"
                        element={<CounsellingReportsPage />}
                    />
                    <Route
                        path="/gbv/court-cases"
                        element={<CourtCasesPage />}
                    />
                    <Route
                        path="/gbv/legal-aid-clinics"
                        element={<LegalAidClinicsPage />}
                    />
                    <Route path="/users" element={<UsersPage />} />
                    <Route path="/login" element={<LoginPage />} />
                    <Route path="/" element={<DashboardPage />} />
                </Routes>
            </BrowserRouter>
        </div>
    );
}

export default App;
