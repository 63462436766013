import {
    UserIcon,
    ArrowRightOnRectangleIcon,
} from "@heroicons/react/24/outline";

export const TopHeader = ({ breadCrumb }) => {
    return (
        <div className="fixed w-[78em] flex justify-between p-8 border-b bg-white">
            <div className="w-10/12">{breadCrumb}</div>
            <div className="w-2/12 flex justify-end space-x-8 pr-4 text-gray-600">
                <a href="/my-account">
                    <UserIcon className="w-6 hover:text-purple-600" />
                </a>
                <ArrowRightOnRectangleIcon
                    className="w-6 cursor-pointer hover:text-purple-600"
                    onClick={() => {
                        localStorage.removeItem("token");
                        window.location.replace("/login");
                    }}
                />
            </div>
        </div>
    );
};
