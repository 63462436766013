import { useState, useEffect } from "react";
import { useSnackbar } from "notistack";
import { ButtonLoader } from "../../buttonLoader";
import { Loader } from "../../loader";
import { Input } from "../templates/input";
import { TextArea } from "../templates/text-area";
import { Select } from "../templates/select";
import { axiosRequest } from "../../../helpers/api-calls";

export const CaseForm = ({ setModal, getData, action, actionID }) => {
    const [disabled, setDisabled] = useState(false);
    const [loading, setLoading] = useState(false);
    const [modalLoading, setModalLoading] = useState(false);
    const [data, updateData] = useState({
        name: "",
        phone_number: "",
        age: "",
        gender: "",
        type: "",
        description: "",
        location: "",
        platform: "web",
    });

    const { enqueueSnackbar } = useSnackbar();

    useEffect(() => {
        if (action === "view") setDisabled(true);

        if (action === "view" || action === "edit")
            axiosRequest(
                "get",
                "gbvcase/" + actionID,
                data,
                setModalLoading,
                setModal,
                getData,
                updateData,
                enqueueSnackbar
            );
    }, []); // eslint-disable-line

    return (
        <div className="px-6 py-6 lg:px-8">
            {modalLoading ? (
                <div className="h-[calc(100vh-7rem)] flex justify-center items-center">
                    <Loader />
                </div>
            ) : (
                <div>
                    <div className="mb-4">
                        <h3 className="text-xl font-medium text-gray-900 dark:text-white">
                            {action.charAt(0).toUpperCase() + action.slice(1)}{" "}
                            GBV case
                        </h3>
                        {action !== "create" && (
                            <h4 className="text-purple-600">
                                case ID: #{actionID}
                            </h4>
                        )}
                    </div>

                    <form className="space-y-4" action="#">
                        <Input
                            item={"name"}
                            type={"text"}
                            disabled={disabled}
                            data={data}
                            updateData={updateData}
                        />
                        <Input
                            item={"phone_number"}
                            type={"text"}
                            disabled={disabled}
                            data={data}
                            updateData={updateData}
                        />
                        <div className="flex space-x-2 justify-between">
                            <div className="w-full">
                                <Input
                                    item={"age"}
                                    type={"number"}
                                    disabled={disabled}
                                    data={data}
                                    updateData={updateData}
                                />
                            </div>
                            <div className="w-full">
                                <Select
                                    item={"gender"}
                                    list={[
                                        "female",
                                        "male",
                                        "prefer not to say",
                                    ]}
                                    disabled={disabled}
                                    data={data}
                                    updateData={updateData}
                                />
                            </div>
                        </div>
                        <Select
                            item={"type"}
                            list={[
                                "rape",
                                "defilement",
                                "physical abuse",
                                "psychological abuse",
                            ]}
                            disabled={disabled}
                            data={data}
                            updateData={updateData}
                        />
                        <TextArea
                            item={"description"}
                            placeholder={"Enter more details on the case"}
                            disabled={disabled}
                            data={data}
                            updateData={updateData}
                        />
                        <Input
                            item={"location"}
                            type={"text"}
                            disabled={disabled}
                            data={data}
                            updateData={updateData}
                        />

                        {action !== "view" && (
                            <button
                                type="submit"
                                className="w-full text-white bg-purple-700 hover:bg-purple-800 focus:ring-4 focus:outline-none focus:ring-purple-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-purple-600 dark:hover:bg-purple-700 dark:focus:ring-purple-800"
                                onClick={(e) => {
                                    e.preventDefault();
                                    axiosRequest(
                                        action === "create" ? "post" : "patch",
                                        `gbvcase${
                                            action === "edit"
                                                ? "/" + actionID
                                                : ""
                                        }`,
                                        data,
                                        setLoading,
                                        setModal,
                                        getData,
                                        updateData,
                                        enqueueSnackbar
                                    );
                                }}
                            >
                                {loading ? <ButtonLoader /> : "SUBMIT"}
                            </button>
                        )}
                    </form>
                </div>
            )}
        </div>
    );
};
