export const Checkbox = ({ item, list, disabled, data, updateData }) => {
    return (
        <div>
            <label
                htmlFor={item}
                className="capitalize block mb-2 text-sm font-medium text-gray-900 dark:text-white"
            >
                {item.replace(/_/g, " ")}
            </label>
            {list.map((value, i) => {
                return (
                    <div className="flex items-center" key={i}>
                        <input
                            id="checked-checkbox"
                            type="checkbox"
                            disabled={disabled}
                            checked={data[item].includes(value)}
                            className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                            onChange={(event) => {
                                let newArray = data[item];
                                event.target.checked &&
                                    newArray.push(value) &&
                                    updateData({
                                        ...data,
                                        [item]: newArray,
                                    });
                            }}
                        />
                        <label
                            htmlFor="checked-checkbox"
                            className="ml-2 my-2 text-sm font-medium capitalize text-gray-900"
                        >
                            {value}
                        </label>
                    </div>
                );
            })}
        </div>
    );
};
