import { Page } from "../../Container/page";
import { getTitles } from "../../helpers/titles";
import { parseBuyersData } from "../../helpers/data-parses";

export const BuyersPage = () => {
    return (
        <Page
            titles={getTitles("buyers")}
            breadCrumb={
                <div className="flex text-gray-400">
                    <p>FISH MANAGEMENT / </p>&nbsp;
                    <a
                        href="/fish-management/buyers"
                        className="hover:text-purple-600"
                    >
                        BUYERS
                    </a>
                </div>
            }
            header={"Buyers"}
            url={"buyers"}
            parseData={parseBuyersData}
        />
    );
};
