import React, { useState } from "react";
import { useSnackbar } from "notistack";
import { EyeIcon, EyeSlashIcon } from "@heroicons/react/24/outline";
import { ButtonLoader } from "../Components/buttonLoader";
import { loginUser } from "../helpers/api-calls";

export const LoginPage = () => {
    const [loading, setLoading] = useState(false);
    const [viewPassword, setViewPassword] = useState(false);
    const [data, updateData] = useState({ username: "", password: "" });

    let disabled = data.username === "" || data.password === "";

    const { enqueueSnackbar } = useSnackbar();

    return (
        <div className="w-screen h-screen flex items-center justify-center px-6 bg-[#9B51E0]">
            <form
                onSubmit={(e) => e.preventDefault()}
                className="bg-white lg:w-4/12 2xl:w-3/12 rounded-xl px-5 py-5 flex flex-col items-center"
            >
                <img
                    src="/logo.jpg"
                    className="w-2/6 my-5"
                    alt="Echo Network Africa"
                />
                <h1 className="text-3xl font-semibold">Welcome back</h1>
                <p className="text-gray-600 mb-5">
                    Please login to access your account.
                </p>
                <div className="w-full mt-3">
                    <label
                        htmlFor="username"
                        className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                    >
                        Email or phone number
                    </label>
                    <input
                        type="text"
                        name="username"
                        id="username"
                        className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-purple-500 focus:border-purple-500 block w-full p-2.5 dark:bg-gray-600 dark:border-gray-500 dark:placeholder-gray-400 dark:text-white"
                        placeholder="Enter email or phone number"
                        value={data.username}
                        onChange={(event) => {
                            updateData({
                                ...data,
                                username: event.target.value,
                            });
                        }}
                    />
                </div>

                <div className="w-full mt-5 mb-5 relative">
                    <label
                        htmlFor="username"
                        className="block mb-2 text-sm font-medium text-gray-900 dark:text-white capitalize"
                    >
                        Password
                    </label>
                    <input
                        type={viewPassword ? "text" : "password"}
                        name="password"
                        id="password"
                        className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-purple-500 focus:border-purple-500 block w-full p-2.5 dark:bg-gray-600 dark:border-gray-500 dark:placeholder-gray-400 dark:text-white"
                        placeholder="Enter password"
                        value={data.password}
                        onChange={(event) => {
                            updateData({
                                ...data,
                                password: event.target.value,
                            });
                        }}
                    />
                    <div
                        className="w-fit cursor-pointer absolute top-12 -translate-y-1/2 right-3"
                        onClick={() => {
                            setViewPassword(!viewPassword);
                        }}
                    >
                        {viewPassword ? (
                            <EyeIcon className="w-6 text-gray-700" />
                        ) : (
                            <EyeSlashIcon className="w-6 text-gray-700" />
                        )}
                    </div>
                </div>

                <button
                    disabled={disabled}
                    className={`w-full ${
                        disabled ? "bg-gray-200" : "bg-[#9B51E0]"
                    } text-white p-3 my-5 rounded-lg`}
                    onClick={() => {
                        loginUser(data, setLoading, enqueueSnackbar);
                    }}
                >
                    {loading ? <ButtonLoader /> : "Login"}
                </button>
            </form>
        </div>
    );
};
