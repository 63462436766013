import { Page } from "../../Container/page";
import { parseSalesData } from "../../helpers/data-parses";
import { getTitles } from "../../helpers/titles";

export const SalesPage = () => {
    return (
        <Page
            titles={getTitles("sales")}
            breadCrumb={
                <div className="flex text-gray-400">
                    <p>FISH MANAGEMENT / </p>&nbsp;
                    <a
                        href="/fish-management/sales"
                        className="hover:text-purple-600"
                    >
                        {" "}
                        SALES
                    </a>
                </div>
            }
            header={"Sales"}
            url={"sales"}
            parseData={parseSalesData}
        />
    );
};
