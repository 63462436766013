import { useState, useEffect } from "react";
import axios from "axios";
import { useSnackbar } from "notistack";
import { TopHeader } from "../../Components/topHeader";
import { IoFishOutline } from "react-icons/io5";
import { GiFishCorpse } from "react-icons/gi";
import { TbCoins } from "react-icons/tb";
import { GiFishBucket } from "react-icons/gi";
import { PieChart } from "../../Components/forms/charts/pie-chart";
import { GroupNavBar } from "../../Components/group-nav-bar";
import { useParams } from "react-router-dom";
import { Loader } from "../../Components/loader";
import { Empty } from "../../Components/empty";

export const GroupDashboard = () => {
    let date = new Date();
    date = new Date(date.setHours(date.getHours() + 3));

    let endDate = date.toISOString().split("T")[0];

    const [range, setRange] = useState({
        endDate: endDate,
        startDate: new Date(date.setMonth(date.getMonth() - 1))
            .toISOString()
            .split("T")[0],
    });
    const [data, updateData] = useState(false);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(false);

    let { id, name } = useParams();

    const getData = () => {
        setLoading(true);
        updateData(false);
        axios({
            method: "get",
            url:
                process.env.REACT_APP_API_URL +
                `dashboard/group/${id}/${range.startDate}/${range.endDate}`,
            headers: {
                "Content-Type": "application/json",
                Authorization: "Bearer " + localStorage.getItem("token"),
            },
        })
            .then(({ data }) => {
                data?.success && updateData(data.data);
                data?.error && setError(data.message);
                setLoading(false);
            })
            .catch((error) => {
                setError(error.message);
                setLoading(false);
            });
    };

    useEffect(() => {
        if (range.startDate) {
            console.log(range);
            getData();
        }
    }, [range]);

    const { enqueueSnackbar } = useSnackbar();

    if (error) {
        enqueueSnackbar(error, {
            variant: "error",
            autoHideDuration: 3000,
        });
    }

    return (
        <div className="bg-zinc-100 w-full h-screen overflow-y-auto">
            <TopHeader
                breadCrumb={
                    <div className="flex text-gray-400">
                        <p>FISH MANAGEMENT</p>
                        <p>&nbsp; / &nbsp;</p>
                        <a
                            href="/fish-management/womengroups"
                            className="hover:text-purple-600"
                        >
                            WOMEN GROUPS
                        </a>
                        <p>&nbsp; / &nbsp;</p>
                        <a
                            href={`/fish-management/womengroup/${id}/${name}/dashboard`}
                            className="hover:text-purple-600"
                        >
                            {name.toUpperCase()}
                        </a>
                    </div>
                }
            />
            <div className="mt-[6.7em] m-5 p-5 rounded-lg bg-white">
                <h3 className="font-semibold text-xl mb-3 capitalize">
                    {name} Dashboard
                </h3>
                <GroupNavBar />
                {!error && (
                    <div>
                        {loading ? (
                            <div className="py-60">
                                <Loader />
                            </div>
                        ) : (
                            <div className="h-max py-5">
                                <section className="w-3/12">
                                    <div className="flex space-x-2 justify-between">
                                        <div className="w-full">
                                            <input
                                                type="date"
                                                name="startDate"
                                                className="bg-gray-50
                                                    border border-gray-300 outline-none text-gray-900 text-sm rounded-lg focus:ring-purple-500 focus:border-purple-500 block w-full p-2.5"
                                                value={range.startDate}
                                                onChange={(event) => {
                                                    setRange({
                                                        ...range,
                                                        startDate:
                                                            event.target.value,
                                                    });
                                                }}
                                            />
                                        </div>
                                        <div className="w-full">
                                            <input
                                                type="date"
                                                name="endDate"
                                                className="bg-gray-50
                                                    border border-gray-300 outline-none text-gray-900 text-sm rounded-lg focus:ring-purple-500 focus:border-purple-500 block w-full p-2.5"
                                                value={range.endDate}
                                                max={endDate}
                                                onChange={(event) => {
                                                    setRange({
                                                        ...range,
                                                        endDate:
                                                            event.target.value,
                                                    });
                                                }}
                                            />
                                        </div>
                                    </div>
                                </section>
                                <section className="flex space-x-4 py-2 items-stretch ">
                                    <div className="shadow-md p-5 rounded-lg bg-white w-full">
                                        <div className="flex justify-between">
                                            <h3 className="text-3xl font-semibold mb-5 text-gray-600">
                                                {data.groupStats.fingerlings
                                                    .toString()
                                                    .replace(
                                                        /\B(?=(\d{3})+(?!\d))/g,
                                                        ","
                                                    )}
                                            </h3>
                                            <IoFishOutline className="text-4xl text-sky-600" />
                                        </div>
                                        <p className="text-gray-400">
                                            FINGERLING COUNT
                                        </p>
                                    </div>
                                    <div className="shadow-md p-5 rounded-lg bg-white w-full">
                                        <div className="flex justify-between">
                                            <h3 className="text-3xl font-semibold mb-5 text-gray-600">
                                                {data.groupStats.mortalities
                                                    .toString()
                                                    .replace(
                                                        /\B(?=(\d{3})+(?!\d))/g,
                                                        ","
                                                    )}
                                            </h3>
                                            <GiFishCorpse className="text-4xl text-sky-600" />
                                        </div>
                                        <p className="text-gray-400">
                                            MORTALITIES
                                        </p>
                                    </div>
                                    <div className="shadow-md  p-5 rounded-lg bg-white w-full">
                                        <div className="flex justify-between">
                                            <h3 className="text-3xl font-semibold mb-5 text-gray-600">
                                                {data.groupStats.harvests
                                                    .toString()
                                                    .replace(
                                                        /\B(?=(\d{3})+(?!\d))/g,
                                                        ","
                                                    )}{" "}
                                                kg
                                            </h3>
                                            <GiFishBucket className="text-4xl text-sky-600" />
                                        </div>
                                        <p className="text-gray-400">
                                            HARVESTS
                                        </p>
                                    </div>
                                    <div className="shadow-md  p-5 rounded-lg bg-white w-full">
                                        <div className="flex justify-between">
                                            <h3 className="text-3xl font-semibold mb-5 text-gray-600">
                                                KES{" "}
                                                {data.groupStats.totalGroupIncome
                                                    .toString()
                                                    .replace(
                                                        /\B(?=(\d{3})+(?!\d))/g,
                                                        ","
                                                    )}
                                            </h3>
                                            <TbCoins className="text-4xl text-sky-600" />
                                        </div>
                                        <p className="text-gray-400">
                                            GROUP INCOME
                                        </p>
                                    </div>
                                </section>
                                <section className="flex space-x-4 py-2">
                                    <div className="shadow-md p-5 rounded-lg bg-white w-7/12">
                                        <p className="text-gray-400">
                                            MORTALITIES
                                        </p>
                                        <div className="h-96">
                                            {data.groupStats.mortalities > 0 ? (
                                                <PieChart
                                                    data={data.graphStats}
                                                />
                                            ) : (
                                                <Empty />
                                            )}
                                        </div>
                                    </div>
                                    <div className="shadow-md p-5 rounded-lg bg-white w-5/12">
                                        <p className="text-gray-400 mb-5">
                                            GROUP INCOME
                                        </p>
                                        <div className="flex justify-between font-semibold mb-5 text-gray-600">
                                            <div className="text-2xl text-gray-400">
                                                FEEDS
                                            </div>
                                            <div className="text-2xl ">
                                                + KES{" "}
                                                {data.groupIncome.feeds
                                                    .toString()
                                                    .replace(
                                                        /\B(?=(\d{3})+(?!\d))/g,
                                                        ","
                                                    )}
                                            </div>
                                        </div>
                                        <div className="flex justify-between font-semibold mb-5 text-gray-600">
                                            <div className="text-2xl text-gray-400">
                                                FREEZER
                                            </div>
                                            <div className="text-2xl ">
                                                + KES{" "}
                                                {data.groupIncome.freezers
                                                    .toString()
                                                    .replace(
                                                        /\B(?=(\d{3})+(?!\d))/g,
                                                        ","
                                                    )}
                                            </div>
                                        </div>
                                        <div className="flex justify-between font-semibold mb-5 text-gray-600">
                                            <div className="text-2xl text-gray-400">
                                                BOATS
                                            </div>
                                            <div className="text-2xl ">
                                                + KES{" "}
                                                {data.groupIncome.boats
                                                    .toString()
                                                    .replace(
                                                        /\B(?=(\d{3})+(?!\d))/g,
                                                        ","
                                                    )}
                                            </div>
                                        </div>
                                        <div className="flex justify-between font-semibold mb-5 text-gray-600">
                                            <div className="text-2xl text-gray-400">
                                                FISH SALES
                                            </div>
                                            <div className="text-2xl ">
                                                + KES{" "}
                                                {data.groupIncome.fishSales
                                                    .toString()
                                                    .replace(
                                                        /\B(?=(\d{3})+(?!\d))/g,
                                                        ","
                                                    )}
                                            </div>
                                        </div>
                                        <div className="flex justify-between border-t-2 py-2 border-dotted font-semibold mb-5 text-black">
                                            <div className="text-2xl">
                                                TOTAL
                                            </div>
                                            <div className="text-2xl ">
                                                KES{" "}
                                                {data.groupIncome.totalGroupIncome
                                                    .toString()
                                                    .replace(
                                                        /\B(?=(\d{3})+(?!\d))/g,
                                                        ","
                                                    )}
                                            </div>
                                        </div>
                                    </div>
                                </section>
                            </div>
                        )}
                    </div>
                )}
            </div>
        </div>
    );
};
